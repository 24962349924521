import React from 'react'
import { NavLink } from 'react-router-dom';
// import { BsMapFill } from 'react-icons/bs';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { GiCampCookingPot } from 'react-icons/gi';
import { FaDiagnoses, FaUsersCog, FaUserFriends, FaUserPlus, FaCartPlus, FaCartArrowDown } from 'react-icons/fa';
import { TfiShoppingCartFull } from 'react-icons/tfi';
import { MdDashboard } from 'react-icons/md';
import { SlGlobe } from 'react-icons/sl';
function SideBar({collapsed, setCollapsed}) {
	
	return (
		<nav className={`sidebar py-2 md:py-0 ${collapsed} `}>
				<div className='my-4 pl-6 whitespace-nowrap hidden md:flex flex-row text-white'>
					<SlGlobe className='mx-2 h-6 w-6'/>
					<span className="title uppercase overflow-hidden">Stoves Sales Tool</span>
				</div>
				<div className=" hidden md:block flex-grow border-t border-green-300 mt-[-0.325rem] mx-2"></div>
				<ul className="flex md:flex-col mx-2 md:mt-5">
				
					<li>
						<NavLink to={'/home'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<MdDashboard className="nav-link-icon" />
							</div>
								<span className='overflow-hidden'>Dashboard</span>
						</NavLink>
					</li>
					{/* <li>
						<NavLink to={'/clusters'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<BsMapFill className="nav-link-icon" />
							</div>
								<span className='overflow-hidden'>Clusters</span>
						</NavLink>
					</li> */}
					<li>
						<NavLink to={'/products'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<GiCampCookingPot className="nav-link-icon" />
							</div>
								<span className='overflow-hidden'>Products</span>
						</NavLink>
					</li>
					<li>
						<NavLink to={'/monitors'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<FaUsersCog className="nav-link-icon" />
							</div>
							<span className='overflow-hidden'>Monitors</span>
						</NavLink>
					</li>
					<li >
						<NavLink to={'/lme/add'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<FaUserPlus className="nav-link-icon" />
							</div>
							<span className='overflow-hidden'>Add LME</span>
						</NavLink>
					</li>
					<li >
						<NavLink to={'/sale/add'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<FaCartArrowDown className="nav-link-icon" />
							</div>
							<span className='overflow-hidden'>Add Sale</span>
						</NavLink>
					</li>
					
					<li>
						<NavLink to={'/lmes'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<FaDiagnoses className="nav-link-icon" />
							</div>
							<span className='overflow-hidden'>All LMEs</span>
						</NavLink>
					</li>

					<li >
						<NavLink to={'/sales'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<TfiShoppingCartFull className="nav-link-icon" />
							</div>
							<span className='overflow-hidden'>All Sales</span>
						</NavLink>
					</li>

					{/* <li className='md:mb-20'>
						<NavLink to={'/customers'} className='group nav-link'>
							<div className="nav-link-icon-wrapper">
								<FaUserFriends className="nav-link-icon" />
							</div>
							<span className='overflow-hidden'>Customers</span>
						</NavLink>
					</li> */}

					<li className='hidden md:flex justify-center w-full py-[0.675rem] rounded-lg px-4'>
						<div className="group nav-link-icon-wrapper mr-0 cursor-pointer hover:bg-gradient-to-tl hover:from-emerald-700 hover:to-green-500 shadow-lg hover:text-white transition-all duration-300 ease-in-out" onClick={()=>setCollapsed(!collapsed)}>
							<MdOutlineArrowBackIosNew className={`nav-link-icon group-hover:text-white  ${collapsed}`} />
						</div>
							
					</li>
				</ul>

			</nav>
	)
}

export default SideBar