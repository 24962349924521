import {
    SALE_LIST_REQUEST,
    SALE_LIST_SUCCESS,
    SALE_LIST_FAIL,

    SALE_CREATE_REQUEST,
    SALE_CREATE_SUCCESS,
    SALE_CREATE_FAIL,
    SALE_CREATE_RESET,

    
    SALES_TREND_REQUEST,
    SALES_TREND_SUCCESS,
    SALES_TREND_FAIL,

    SALES_PER_PRODUCT_REQUEST,
    SALES_PER_PRODUCT_SUCCESS,
    SALES_PER_PRODUCT_FAIL,

    PRODUCT_SALES_TREND_REQUEST,
    PRODUCT_SALES_TREND_SUCCESS,
    PRODUCT_SALES_TREND_FAIL,

    SALE_UPDATE_REQUEST,
    SALE_UPDATE_SUCCESS,
    SALE_UPDATE_FAIL,
    SALE_UPDATE_RESET,

    SALE_DELETE_REQUEST,
    SALE_DELETE_SUCCESS,
    SALE_DELETE_FAIL,

    SALES_PER_CLUSTER_REQUEST,
    SALES_PER_CLUSTER_SUCCESS,
    SALES_PER_CLUSTER_FAIL,

    SALES_PER_LME_REQUEST,
    SALES_PER_LME_SUCCESS,
    SALES_PER_LME_FAIL,

    SALES_PER_LMETYPE_REQUEST,
    SALES_PER_LMETYPE_SUCCESS,
    SALES_PER_LMETYPE_FAIL,

    SALES_PER_COUNTY_REQUEST,
    SALES_PER_COUNTY_SUCCESS,
    SALES_PER_COUNTY_FAIL,

    SALE_IMPORT_REQUEST,
    SALE_IMPORT_SUCCESS,
    SALE_IMPORT_FAIL,
    SALE_IMPORT_RESET,
    SALE_ALL_FAIL,
    SALE_ALL_SUCCESS,
    SALE_ALL_REQUEST,
    COUNTY_LIST_FAIL,
    COUNTY_LIST_SUCCESS,
    COUNTY_LIST_REQUEST,

} from '../constants/saleConstants'

export const saleListReducer = (state = { sales: [], selectProductOptions: []}, action) => {
    switch (action.type) {
        case SALE_LIST_REQUEST:
            return { loading: true, sales: [] }

        case SALE_LIST_SUCCESS:
            
            return {
                loading: false,
                sales: action.payload.sales,
                page: action.payload.page,
                pages: action.payload.pages,
                
            }

        case SALE_LIST_FAIL:
            return { loading: false, error: action.payload, sales:[] }

        default:
            return state
    }
}

export const saleAllReducer = (state = { all_sales: []}, action) => {
    switch (action.type) {
        case SALE_ALL_REQUEST:
            return { loading: true, all_sales: [] }

        case SALE_ALL_SUCCESS:
            
            
           
            
    
            return {
                loading: false,
                all_sales: action.payload,
            }

        case SALE_ALL_FAIL:
            return { loading: false, error: action.payload, all_sales:[] }

        default:
            return state
    }
}

export const saleCreateReducer = (state = {sale:[]}, action) => {
    switch (action.type) {
        case SALE_CREATE_REQUEST:
            return { loading: true, sale:[] }

        case SALE_CREATE_SUCCESS:
            return { loading: false, success: true, sale: action.payload }

        case SALE_CREATE_FAIL:
            return { loading: false, error: action.payload, sale:[] }

        case SALE_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const salesTrendReducer = (state = { trends: []}, action) => {
    switch (action.type) {
        case SALES_TREND_REQUEST:
            return { loading: true, trends: [] }

        case SALES_TREND_SUCCESS:
            
           
            return {
                loading: false,
                trends: action.payload
            }

        case SALES_TREND_FAIL:
            return { loading: false, error: action.payload, trends: [] }

        default:
            return state
    }
}

export const salesPerProductReducer = (state = { perProducts: []}, action) => {
    switch (action.type) {
        case SALES_PER_PRODUCT_REQUEST:
            return { loading: true, perProducts: [] }

        case SALES_PER_PRODUCT_SUCCESS:
            
           
            return {
                loading: false,
                perProducts: action.payload
            }

        case SALES_PER_PRODUCT_FAIL:
            return { loading: false, error: action.payload, perProducts: [] }

        default:
            return state
    }
}

export const productSalesTrendReducer = (state = { productTrends: []}, action) => {
    switch (action.type) {
        case PRODUCT_SALES_TREND_REQUEST:
            return { loading: true, productTrends: [] }

        case PRODUCT_SALES_TREND_SUCCESS:
            
           
            return {
                loading: false,
                productTrends: action.payload
            }

        case PRODUCT_SALES_TREND_FAIL:
            return { loading: false, error: action.payload, productTrends: [] }

        default:
            return state
    }
}

export const saleDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case SALE_DELETE_REQUEST:
            return { loading: true }

        case SALE_DELETE_SUCCESS:
            return { loading: false, success: true }

        case SALE_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const saleUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case SALE_UPDATE_REQUEST:
            return { loading: true }

        case SALE_UPDATE_SUCCESS:
            return { loading: false, success: true, response: action.payload }

        case SALE_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case SALE_UPDATE_RESET:
            return { state: {} }

        default:
            return state
    }

}

export const salesPerCountyReducer = (state = { perCountys: []}, action) => {
    switch (action.type) {
        case SALES_PER_COUNTY_REQUEST:
            return { loading: true, perCountys: [] }

        case SALES_PER_COUNTY_SUCCESS:
            
           
            return {
                loading: false,
                perCountys: action.payload
            }

        case SALES_PER_COUNTY_FAIL:
            return { loading: false, error: action.payload, perCountys: [] }

        default:
            return state
    }
}

export const salesPerClusterReducer = (state = { perClusters: []}, action) => {
    switch (action.type) {
        case SALES_PER_CLUSTER_REQUEST:
            return { loading: true, perClusters: [] }

        case SALES_PER_CLUSTER_SUCCESS:
            
           
            return {
                loading: false,
                perClusters: action.payload
            }

        case SALES_PER_CLUSTER_FAIL:
            return { loading: false, error: action.payload, perClusters: [] }

        default:
            return state
    }
}

export const salesPerLmetypeReducer = (state = { perLmetypes: []}, action) => {
    switch (action.type) {
        case SALES_PER_LMETYPE_REQUEST:
            return { loading: true, perLmetypes: [] }

        case SALES_PER_LMETYPE_SUCCESS:
            
           
            return {
                loading: false,
                perLmetypes: action.payload
            }

        case SALES_PER_LMETYPE_FAIL:
            return { loading: false, error: action.payload, perLmetypes: [] }

        default:
            return state
    }
}

export const salesPerLmeReducer = (state = { perLmes: []}, action) => {
    switch (action.type) {
        case SALES_PER_LME_REQUEST:
            return { loading: true, perLmes: [] }

        case SALES_PER_LME_SUCCESS:
            
           
            return {
                loading: false,
                perLmes: action.payload
            }

        case SALES_PER_LME_FAIL:
            return { loading: false, error: action.payload, perLmes: [] }

        default:
            return state
    }
}

export const saleImportReducer = (state = {importedSales:[]}, action) => {
    switch (action.type) {
        case SALE_IMPORT_REQUEST:
            return { loading: true, importedSales:[] }

        case SALE_IMPORT_SUCCESS:
            return { loading: false, success: true, importedSales: action.payload }

        case SALE_IMPORT_FAIL:
            return { loading: false, error: action.payload, importedSales:[] }

        case SALE_IMPORT_RESET:
            return {importedSales:[]}

        default:
            return state
    }
}

export const countyListReducer = (state = { counties: []}, action) => {
    switch (action.type) {
        case COUNTY_LIST_REQUEST:
            return { loading: true, counties: [] }

        case COUNTY_LIST_SUCCESS:
            
            return {
                loading: false,
                counties: action.payload
            }

        case COUNTY_LIST_FAIL:
            return { loading: false, error: action.payload, counties:[] }

        default:
            return state
    }
}
