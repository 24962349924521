import axios from 'axios'
import {
    LME_LIST_REQUEST,
    LME_LIST_SUCCESS,
    LME_LIST_FAIL,

    LME_DETAILS_REQUEST,
    LME_DETAILS_SUCCESS,
    LME_DETAILS_FAIL,

    LME_DELETE_REQUEST,
    LME_DELETE_SUCCESS,
    LME_DELETE_FAIL,
    LME_DELETE_RESET,

    LME_CREATE_REQUEST,
    LME_CREATE_SUCCESS,
    LME_CREATE_FAIL,

    LME_UPDATE_REQUEST,
    LME_UPDATE_SUCCESS,
    LME_UPDATE_FAIL,
    LME_IMPORT_REQUEST,
    LME_IMPORT_SUCCESS,
    LME_IMPORT_FAIL,

} from '../constants/lmeConstants'


export const listLmes = () => async (dispatch, getState) => {
    try {
        dispatch({ type: LME_LIST_REQUEST })
        dispatch({ type: LME_DELETE_RESET })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/lmes/`, config)

        dispatch({
            type: LME_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LME_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}




export const listLmeDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: LME_DETAILS_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/lmes/${id}`, config)

        dispatch({
            type: LME_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LME_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteLme = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LME_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/lmes/delete/${id}/`,
            {},
            config
        )

        dispatch({
            type: LME_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: LME_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createLme = (lme) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LME_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/lmes/create/`,
            lme,
            config
        )
        dispatch({
            type: LME_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: LME_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const importLmes = (lmes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LME_IMPORT_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/lmes/import/`,
            lmes,
            config
        )
        dispatch({
            type: LME_IMPORT_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: LME_IMPORT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const updateLme = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LME_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const  { data }  = await axios.post(
            `/api/lmes/update/`,
            changes,
            config
        )
        dispatch({
            type: LME_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: LME_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: LME_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
