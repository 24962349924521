import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { listProducts } from '../actions/productActions'
import Alert from '../components/Alert'
import Loader from '../components/Loader'

function ProductScreen() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const productList = useSelector(state=> state.productList)
  const {error, loading, products} = productList

  const userLogin = useSelector(state=> state.userLogin)
  const {userInfo} = userLogin

  useEffect(() => {
	window.scrollTo(0,0)
	if (userInfo){
	  dispatch(listProducts())
	}else{
	  navigate('/')
	}
  }, [dispatch])
  
  return (
	<div className='container'>
		{loading && <Loader fullscreen />}
	  {error? <Alert variant='error'>{error}</Alert>:
	  <div className="row">
		{
		  products.map(item=>
			(<div className="col-2 flex justify-center" key={item._id}>
				<Link className='flex flex-col items-start' to={`products/${item._id}`}>
				  <img className='rounded-md max-h-60 self-center' src={item.image} alt={item.image} />
				  <div className="text-sm font-semibold">Name: {item.name}</div>
				  <div className="text-sm">Efficiency: {item.efficiency}</div>
				</Link>
			</div>)
		  )
		}
	  </div>}
	  
		
	</div>
  )
}

export default ProductScreen