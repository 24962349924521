import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteSale, listSales, updateSale } from '../actions/saleActions';
import Alert from '../components/Alert';
import Loader from '../components/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport, ColumnToggle} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { SiMicrosoftexcel } from 'react-icons/si'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { SALE_IMPORT_RESET, SALE_UPDATE_RESET } from '../constants/saleConstants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { listLmes } from '../actions/lmeActions';
import { listProducts } from '../actions/productActions';
import generateTemplte from '../functions/generateTemplte';
import { useToast } from '@chakra-ui/react'
import Paginate from '../components/Paginate';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';




function SaleScreen() {
	const toast = useToast()
	
	
    
	const [changes, setChanges] = useState([])
    const dispatch = useDispatch()
	const navigate = useNavigate()

  // Called after user completes the flow. Provides data array, where data keys matches your field keys.
  	

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

	const saleList = useSelector(state=> state.saleList)
	const {loading: loadingSales, error: errorSales, sales, pages, page} = saleList

	const saleCreate = useSelector(state=> state.saleCreate)
	const {success} = saleCreate

	

	const saleUpdate = useSelector(state => state.saleUpdate)
    const { error: errorUpdate, loading: loadingUpdate, response, success:successUpdate} = saleUpdate

	const saleDelete = useSelector(state => state.saleDelete)
    const { error: errorDelete, loading: loadingDelete, success:successDelete} = saleDelete

	const lmeList = useSelector(state=> state.lmeList)
	const {loading: loadingLMEs, error: errorLMEs , lmes} = lmeList

	const productList = useSelector(state=> state.productList)
  	const {error:errorProducts, loading:loadingProducts, products} = productList

	const [searchParams, setSearchParams] = useSearchParams()

	

	let keyword = searchParams.toString()

	



    

	useEffect(() => {
		window.scrollTo(0,0)
		if (successUpdate){
			setChanges([])
			setTimeout(() => {
				dispatch({type: SALE_UPDATE_RESET})
				
			}, 2000);
		}
		
		if(!userInfo){
            navigate('/')
        }else{
	  		dispatch(listSales(keyword))
			dispatch(listLmes())
			dispatch(listProducts())
		}
		
	}, [dispatch, success, successUpdate, userInfo, successDelete, keyword])

    const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;
	

	const handleDelete = (id)=>{
        if (window.confirm(`Permanently Delete this Sale? This action is irreversible`)) {
            dispatch(deleteSale(id))
        }
    }

	
    
	

    const columns = [{
		dataField: '_id',
		text: 'ID',
		sort:true,
		editable: false,
		sortCaret: sortCaret,
		csvExport: false
        
	  },{
		dataField:'date',
		text:'Date',
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField: 'lme_name',
		text: 'LME',
		sort: true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(sales.map(item => item.lme_name))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by LME',
			className:'column-filter'
		  })
		
		
	  },{
		dataField:'lme_category',
		text:'LME Type',
		sort:true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(sales.map(item => item.lme_category))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by LME Type',
			className:'column-filter'
		  })
        
	  },{
		dataField:'cluster',
		text:'Cluster',
		sort:true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(sales.map(item => item.cluster))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by Cluster',
			className:'column-filter'
		  })

        
	  },{
		dataField:'lme_county',
		text:'County',
		sort:true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(sales.map(item => item.lme_county))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by County',
			className:'column-filter'
		  })
	  },{
		dataField:'lme_sub_county',
		text:'Sub County',
		hidden:true,
		editable: false,
		sortCaret: sortCaret,
		
	  },{
		dataField:'lme_ward',
		text:'Ward',
		hidden:true,
		editable: false,
		sortCaret: sortCaret,
		
	  },{
		dataField: 'customer_name',
		text: 'Customer Name',
		sort: true,
		editable: false,
		sortCaret: sortCaret,
		
		
	  },{
		dataField: 'customer_phone_number',
		text: 'Customer Contact',
		
		editable: true,
		
	  },{
		dataField: 'customer_county',
		text: 'Customer County',
		hidden: true,
		editable: false,
		
	  },{
		dataField: 'product',
		text: 'Product',
		sort: true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(sales.map(item => item.product))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by Product',
			className:'column-filter'
		  })
		
		
	  },{
		dataField:'quantity',
		text:'Quantity',
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField:'unitPrice',
		text:'Unit Price',
		sort:true,
		sortCaret: sortCaret,
		editable: false,
		hidden: true
        
	  },{
		dataField:'totalPrice',
		text:'Total Price',
		sort:true,
		sortCaret: sortCaret,
		editable: false,
		hidden: true
        
	  },{
        dataField: 'df1',
        isDummyField: true,
        text: 'Delete',
        classes:'text-center',
        headerClasses:'text-center',
        editable: false,
		csvExport: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button onClick={()=>handleDelete(row._id)} className='delete'> <FaTrash /> </button>
              
            );
          
        }
      }];

	  
  return (
    <div className="container md:px-6">
        
        <div className='flex px-0 p-9 pb-4 flex-row items-center justify-between'>
			<h1>Sales</h1>
			
		</div>

        <div className="dash-card px-0 md:px-4 w-full min-h-[500px] sales">
					
					<div className="table-wrapper">
					
                    {loadingLMEs|| loadingSales || loadingUpdate || loadingDelete?<Loader fullscreen /> : errorLMEs|| errorSales || errorUpdate? <Alert variant={'error'}>{ errorLMEs|| errorSales || errorUpdate || errorDelete}</Alert>:''}
                    {(!errorSales && !loadingSales) && <ToolkitProvider
							columnToggle
							
                            search
                            selectFilter
                            keyField="_id"
                            data={ sales }
                            columns={ columns }
							exportCSV={ {
                                fileName: 'Sales.csv',
                                exportAll:false
                              } }
							  
							 
							
                            
                            
                            >
						{
							props => (
							<div>
								<div className='flex flex-wrap justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-green-500">
										Sales
									</div>
									<div className='flex flex-row space-x-2'>
										<button className="button" onClick={()=>dispatch(listSales())}>Refresh</button>
										<button className="button csv" disabled={changes.length===0} onClick={()=>dispatch(updateSale(changes))}>Save Changes</button>
										<ExportCSVButton { ...props.csvProps } className="button csv" ><SiMicrosoftexcel /> </ExportCSVButton>
										
										<SearchBar srText={''} className='w-32' { ...props.searchProps } />
									</div>
									
								</div>
								{response && <Alert variant={'success'}>{response}</Alert>}
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-8 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() }
								pagination={ paginationFactory({sizePerPage:100, sizePerPageList: [{ text: '500', value: 500 }, { text: '1000', value: 1000 }, { text: '5000', value: 5000 }, { text: '10000', value: 10000 }, { text: 'All', value: sales.length } ]}) }
								cellEdit={ cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: (oldValue, newValue, row, column) => { setChanges([...changes, {value: newValue, _id: row._id, dataField: column.dataField}]); }
									}) } 
                                 
								/>
							</div>
							)
						}
						
						</ToolkitProvider>}
						
					</div>
				</div>
    </div>
  )
}

export default SaleScreen