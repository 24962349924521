import React, { useEffect, useState } from 'react'
// import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { GiCampCookingPot} from 'react-icons/gi';
import { TfiShoppingCartFull, TfiHome } from 'react-icons/tfi';
import { AiOutlineShop } from 'react-icons/ai'
import { FaCoins } from 'react-icons/fa';
import CountUp from 'react-countup';
import ReactApexChart from 'react-apexcharts'
import DashChart from '../components/DashChart';
import { useNavigate } from 'react-router-dom';
import { getProductSalesTrend, getSalesPerCluster, getSalesPerLmetype, getSalesPerProduct, getSalesTrend, listSales, getSalesPerCounty, listAllSales, getSalesPerLme, listCounties } from '../actions/saleActions';
import { listProducts } from '../actions/productActions';
import { listLmes } from '../actions/lmeActions';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import { ComposableMap, Geographies, Geography } from "react-simple-maps" 
import KenyaMap from '../charts/KenyaMap.js';
import * as d3 from "d3";
import { ShimmerTable, ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";

function DashBoardScreen() {
	
	const [cash, setCash] = useState('quantity')
	const [county, setCounty] = useState('')

	

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const dispatch = useDispatch()
	const navigate = useNavigate()
	const geoURL = 'https://raw.githubusercontent.com/DeveloperFelix/Kenya-County-GeoJSON-Downloader/master/json/ke.geojson'

	const salesPerCounty = useSelector(state => state.salesPerCounty)
	const {loading:loadingPerCounty, error:errorPerCounty, perCountys} = salesPerCounty
	
	

	

	



	useEffect(() => {
        window.scrollTo(0,0)
        if(!userInfo){
            navigate('/')
        }else{
            dispatch(listProducts())
            dispatch(listAllSales())
            dispatch(listLmes())
            dispatch(getSalesTrend())
            dispatch(getSalesPerProduct())
            dispatch(getProductSalesTrend())
			dispatch(getSalesPerLme())
			dispatch(getSalesPerLmetype())
			dispatch(getSalesPerCluster())
			dispatch(getSalesPerCounty())
			dispatch(listCounties())
			
        }
        
    }, [userInfo, dispatch])

	const productList = useSelector(state => state.productList)
    const{loading:loadingProducts, error: errorProducts, products} = productList


	const allSales = useSelector(state=> state.allSales)
	const {loading: loadingSales, error: errorSales, all_sales} = allSales

	const lmeList = useSelector(state=> state.lmeList)
	const {loading: loadingLMEs, error: errorLMEs, lmes} = lmeList

	const salesPerProduct = useSelector(state => state.salesPerProduct)
	const {loading:loadingPerProduct, error:errorPerProduct, perProducts} = salesPerProduct

	const salesPerCluster = useSelector(state => state.salesPerCluster)
	const {loading:loadingPerCluster, error:errorPerCluster, perClusters} = salesPerCluster

	const countyList = useSelector(state => state.countyList)
	const {loading:loadingCounties, error: errorCounties, counties} = countyList

	const salesPerLmetype = useSelector(state => state.salesPerLmetype)
	const {loading:loadingPerLmeType, error:errorPerLmeType, perLmetypes} = salesPerLmetype

	const salesPerLme = useSelector(state => state.salesPerLme)
	const {loading:loadingPerLme, error:errorPerLme, perLmes} = salesPerLme

	const productSalesTrend = useSelector(state => state.productSalesTrend)

    const {loading, error, productTrends} = productSalesTrend

	const salesTrend = useSelector(state => state.salesTrend)

    const {loading:loadingTrend, error:errorTrend, trends} = salesTrend
	
	
	
	const trendSeries = products.map((p)=>{
		var filteredArray = productTrends.filter(item => item.product==p.name)
		
          trends.forEach(element => {
			
            if (!(filteredArray.find(item => item.month==element.month && item.year==element.year))){
				
              filteredArray.push({
                  month:element.month,
                  totalCash:0,
                  totalQuantity:0,
                  product: p.name,
				  year:element.year,
              })
			  
            }
          });

		  const productArray = filteredArray.sort((a,b) => new Date("01 "+a.month + " " +a.year).getTime() - new Date("01 "+b.month + " " +b.year).getTime())
		  
		  
		  return {name: p.name, data: productArray.map((item)=> { return {x:item.month+' '+ item.year, y:item.totalQuantity}} )}
	})
	
	const trendSeries1 = products.map((p)=>{
		var filteredArray = productTrends.filter(item => item.product==p.name)
		
          trends.forEach(element => {
			
            if (!(filteredArray.find(item => item.month==element.month && item.year==element.year))){
				
              filteredArray.push({
                  month:element.month,
                  totalCash:0,
                  totalQuantity:0,
                  product: p.name,
				  year:element.year,
              })
			  
            }
          });

		  const productArray = filteredArray.sort((a,b) => new Date("01 "+a.month + " " +a.year).getTime() - new Date("01 "+b.month + " " +b.year).getTime())
		  
		  
		  return {name: p.name, data: productArray.map((item)=> { return {x:item.month+' '+ item.year, y:item.totalCash}} )}
	})

	

	  const options = {
		
		chart: {
		  height: 500,
		  type: 'area',
		  
		  zoom: {
			enabled: false
		  }
		},
		dataLabels: {
		  enabled: false
		},
		stroke: {
		  curve: 'smooth'
		},
		
		tooltip: {
			x: {
			  format: 'dd/MM/yyyy'
			},
		  },
		}

		const pieSeries =  perProducts.map(item=>item.totalQuantity)
		const pieSeries1 =  perProducts.map(item=>item.totalCash)
		
		const pieOptions = {
			chart: {
			
			type: 'pie',
			},
			
			labels: perProducts.map(item=>item.product),
			responsive: [{
				breakpoint: 480,
					options: {
						chart: {
						
						},
						legend: {
						position: 'bottom'
						}
					}
				}],
			fill: {
				type: 'gradient',
			},
			dataLabels: {
				enabled: false
			}

		}

		const donutSeries =  perLmetypes.map(item=>item.totalQuantity)

		const donutSeries1 =  perLmetypes.map(item=>item.totalCash)

		const donutOptions = {
			chart: {
			
			type: 'pie',
			},
			
			labels: perLmetypes.map(item=>item.type),
			responsive: [{
			breakpoint: 480,
				options: {
					chart: {
					
					},
					legend: {
					position: 'bottom'
					}
				}
			}],
			fill: {
				type: 'gradient',
			},
			dataLabels: {
				enabled: false
			}

		}

		const barSeries = [{
			name: "Sales",
			data: perClusters.map((item)=>{return {x:item.cluster, y:item.totalQuantity}})
		}]

		const barSeries1 = [{
			name: "Sales",
			data: perClusters.map((item)=>{return {x:item.cluster, y:item.totalCash}})
		}]

		
		
		const barOptions = {
			fill:{
				opacity: 0.4,
				
			},

			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'round',
				
				width: 2,
				dashArray: 0,      
			},
			
			chart: {
			  type: 'bar',
			  redrawOnParentResize: true
			  
			},
			plotOptions: {
			  bar: {
				borderRadius: 4,
				horizontal: false,
				distributed: true
			  }
			},
			dataLabels: {
			  enabled: false
			},
			
			legend:{
				show: false
			}
			
			

		  }
		  useEffect(() => {
			window.scrollTo(0,0)
		  }, [])
		
		  const topLmes = perLmes.sort((a, b)=> {return b.totalQuantity - a.totalQuantity}).slice(0, 10)
		  const topCounties = perCountys.sort((a, b)=> {return b.totalQuantity - a.totalQuantity}).slice(0, 10)
		  const topCounties1 = perCountys.sort((a, b)=> {return b.totalCash - a.totalCash}).slice(0, 10)

		  
	return (
		<div className="container">
			
			<h1 className='p-9 pb-0'>DashBoard</h1>
			
			{(errorPerCluster || errorPerLmeType || errorPerProduct || error || errorTrend || errorProducts || errorSales || errorLMEs) && <Alert variant={'error'}>{errorPerCluster || errorPerLmeType || errorPerProduct || error || errorTrend || errorProducts || errorSales || errorLMEs}</Alert>}
			<div className="row">
				
				

				<div className="col-3 flex">
					<div className="dash-card w-full min-h-[94px]">
						{(loadingLMEs || errorLMEs) ? <ShimmerTitle line={2} gap={10} variant="secondary" />:<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Total LMEs: <CountUp
										start={0}
										end={lmes.length}
										duration={1}
										separator=","
										className='font-bold break-words text-sm text-slate-500'
									/></div>
								<div className="flex flex-col ">
									<div className='dash-card-value'>
										
										<CountUp
											start={0}
											end={lmes.filter(l=> l.gender==="FEMALE").length}
											duration={1}
											separator=","
											prefix='Female: '
										/>
									</div>
									<div className='dash-card-value'>
										<CountUp
											start={0}
											end={lmes.filter(l=> l.gender==="MALE").length}
											duration={1}
											separator=","
											prefix='Male: '
										/>
									</div>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<AiOutlineShop className='dash-card-icon' />
								</div>
							</div>
						</div>}
					</div>
				</div>

				<div className="col-3 flex">
					<div className="dash-card w-full min-h-[94px]">
					{(loadingSales || errorSales)? <ShimmerTitle line={2} gap={10} variant="secondary" />:<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Total Items Sold: <CountUp
										start={0}
										end={all_sales.reduce((acc, item) => acc + Number(item.quantity), 0)}
										duration={1}
										separator=","
										className='font-bold break-words text-sm text-slate-500'
									/>
								</div>
								<div className="flex flex-col ">
									<div className='dash-card-value'>
										
										<CountUp
											start={0}
											end={all_sales.filter(l=> l.lme_gender==="FEMALE").reduce((acc, item) => acc + Number(item.quantity), 0)}
											duration={1}
											separator=","
											prefix='Female: '
										/>
									</div>
									<div className='dash-card-value'>
										<CountUp
											start={0}
											end={all_sales.filter(l=> l.lme_gender==="MALE").reduce((acc, item) => acc + Number(item.quantity), 0)}
											duration={1}
											separator=","
											prefix='Male: '
										/>
									</div>
								</div>
								
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<TfiShoppingCartFull className='dash-card-icon' />
								</div>
							</div>
						</div>}
					</div>
				</div>

				<div className="col-3 flex">
					<div className="dash-card w-full min-h-[94px]">
					{(loadingSales || errorSales)? <ShimmerTitle line={2} gap={10} variant="secondary" />:
						<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Sales Amount: <CountUp
										start={0}
										end={all_sales.reduce((acc, item) => acc + Number(item.totalPrice), 0)}
										duration={1}
										separator=","
										prefix='Ksh. '
										className='font-bold break-words text-sm text-slate-500'
									/></div>
								<div className="flex flex-col ">
									<div className='dash-card-value'>
										
										<CountUp
											start={0}
											end={all_sales.filter(l=> l.lme_gender==="FEMALE").reduce((acc, item) => acc + Number(item.totalPrice), 0)}
											duration={1}
											separator=","
											prefix='Female: Ksh. '
										/>
									</div>
									<div className='dash-card-value'>
										<CountUp
											start={0}
											end={all_sales.filter(l=> l.lme_gender==="MALE").reduce((acc, item) => acc + Number(item.totalPrice), 0)}
											duration={1}
											separator=","
											prefix='Male: Ksh. '
										/>
									</div>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<FaCoins className='dash-card-icon' />
								</div>
							</div>
						</div>}
						
					</div>
				</div>

				
				
			</div>
			<div className='md:mt-6 flex justify-end pr-8'>
				<select value={cash} onChange={(e)=>setCash(e.target.value)}>
					<option value={'quantity'}>Quantity</option>
					<option value={'cash'}>Cash</option>
				</select>
			</div>

			<div className="row">
				
				<div className='col-1 flex'>
					<div className="dash-card w-full md:min-h-[500px]">
						<div className="card-title px-4 font-bold text-green-500">
							Sales Trend
						</div>
						<div className="w-auto overflow-hidden md:min-h-[500px]">
							{(loadingTrend || loading || loadingProducts||errorTrend || error || errorProducts) ? <ShimmerThumbnail height={500}  rounded />: <ReactApexChart options={options} series={cash==='cash' ? trendSeries1:trendSeries} type="area" height={400} />}
						</div>
						
					</div>
				</div>

				

				

				



			</div>

			<div className="row md:mt-6">
					<div className='col-2 flex'>
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-green-500">
								Sales Per Cluster
							</div>
							<div className="w-full overflow-hidden">
								{ (loadingPerCluster || errorPerCluster) ? <ShimmerThumbnail height={500}  rounded /> : <DashChart options={barOptions} data={cash==='cash'? barSeries1: barSeries} type={"bar"} height={600} width={'100%'}/>}
							</div>
							
						</div>

						
					</div>

					<div className='col-2 flex'>
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-green-500 mb-4">
								Sales per County
							</div>
							<div className="flex flex-col  justify-center pt-8">
							{/* <h3 className='font-light text-sm mb-4 text-gray-300 mt-8'>Hover to view sales numbers</h3> */}
								{(errorCounties || loadingCounties) ? <ShimmerThumbnail height={500} rounded /> : <KenyaMap counties={counties} />}
							
							</div>
							
						</div>

					</div>

					
			</div>

			<div className="row md:mt-6">

					<div className='col-2 flex'>
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-green-500">
								Sales Per Product
							</div>
							<div className="w-full overflow-hidden md:min-h-[500px]">
								{(errorPerProduct || loadingPerProduct) ? <ShimmerThumbnail height={500}  rounded /> : <DashChart options={pieOptions} data={cash==='cash'? pieSeries1: pieSeries} type={"pie"} height={400}/>}
							</div>
							
						</div>
					</div>
					<div className='col-2 flex'>
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-green-500">
								Sales Per LME Type
							</div>
							{ (loadingPerLmeType || errorPerLmeType) ? <ShimmerThumbnail height={500}  rounded /> : <DashChart options={donutOptions} data={cash==='cash'? donutSeries1: donutSeries} type={"donut"} height={400} />}
							
						</div>

						
					</div>
			</div>

			<div className="row md:mt-6">
				
					

					<div className='col-2 flex'>
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-green-500">
								Top LMES
							</div>
							<div className="table-wrapper flex">
								<table className='items-center  mb-0 align-top border-gray-200 text-slate-500  w-fit'>
									<thead>
										<tr>
											<th>#</th>
											<th>Name</th>
											<th>County</th>
											<th>Category</th>
											<th>Sales</th>
										</tr>
									</thead>
									<tbody className=''>
										<tr></tr>
										{(loadingLMEs|| errorLMEs)? <tr><td colSpan={5}><ShimmerThumbnail height={500}  rounded /></td></tr>:topLmes.map((l, index)=>
											<tr key={index}>
												<td>{index+1}</td>
												<td>{l.name}</td>
												<td>{l.county}</td>
												<td>{l.category}</td>
												<td className='font-bold'>{l.totalQuantity}</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							
						</div>

						
					</div>

					<div className='col-2 flex'>
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-green-500">
								Top Counties
							</div>
							<div className="table-wrapper">
								{/* <KenyaMap /> */}
								<table className='items-center w-full mb-0 align-top border-gray-200 text-slate-500'>
									<thead>
									<tr><th>#</th>
										<th>County</th>
										<th>Sales</th></tr>
									</thead>
									<tbody>
									<tr></tr>
										{(loadingPerCounty|| errorPerCounty) ? <td colSpan={3}><ShimmerThumbnail height={500}  rounded /></td> :topCounties.map((l, index)=>
											<tr key={l.county}>
												<td>{index+1}</td>
												<td>{l.county}</td>
												<td className='font-bold'>{l.totalQuantity}</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							
						</div>

					</div>
			</div>
		</div>
		
	)
}

export default DashBoardScreen