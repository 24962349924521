import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { deleteLme, importLmes, listLmes, updateLme } from '../actions/lmeActions';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { SiMicrosoftexcel } from 'react-icons/si'
import idFormatter from '../functions/idFormatter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { LME_UPDATE_RESET } from '../constants/lmeConstants';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import generateTemplte from '../functions/generateTemplte';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { listMonitors } from '../actions/monitorActions';
import { BiDetail } from 'react-icons/bi';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

function LMEsScreen() {
	const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;

	const dispatch = useDispatch()
	
	
	const [changes, setChanges] = useState([])
	const navigate = useNavigate()
	
	

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

	const lmeList = useSelector(state=> state.lmeList)
	const {loading: loadingLMEs, error: errorLMEs, stockists,producers,installers,distributors, lmes} = lmeList

	const lmeCreate = useSelector(state=> state.lmeCreate)
	const {success} = lmeCreate

	const lmeUpdate = useSelector(state => state.lmeUpdate)
    const { error: errorUpdate, loading: loadingUpdate, response, success:successUpdate} = lmeUpdate

	const lmeDelete = useSelector(state => state.lmeDelete)
    const { error: errorDelete, loading: loadingDelete, success:successDelete} = lmeDelete

	

	const monitorList = useSelector(state=> state.monitorList)
	const {loading: loadingMonitors, error: errorMonitors, monitors} = monitorList


	

	useEffect(() => {
		
		window.scrollTo(0,0)
		if(!userInfo){
            navigate('/')
        }else{
			dispatch(listLmes())
			dispatch(listMonitors())
		}
		if (successUpdate){
			setChanges([])
			setTimeout(() => {
				dispatch({type: LME_UPDATE_RESET})
				
			}, 2000);
		}
	}, [dispatch, success, successUpdate, userInfo, successDelete, success])

	const handleDelete = (id, name)=>{
        if (window.confirm(`Permanently Delete this LME: ${name}? This action is irreversible`)) {
            dispatch(deleteLme(id))
        }
    }

	const columns = [{
		dataField: '_id',
		text: 'ID',
		sort:true,
		editable: false,
		sortCaret: sortCaret,
		csvExport: false
		
	  },{
		dataField: 'name',
		text: 'LME Name',
		sort: true,
		sortCaret: sortCaret,
		
	  },{
		dataField: 'phone_number',
		text: 'LME Contact',
		sort: true,
		sortCaret: sortCaret,
		
	  },{
		dataField: 'type_of_enterprise',
		text: 'Enterprise Type',
		editable: false,
		sort: true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options: [
				{label:'Individual', value:'Individual'},
				{label:'Group', value:'Group'},
			],
			placeholder: 'Filter by Type',
			className:'column-filter'
		  })
		
		
	  },{
		dataField:'category',
		text:'Category',
		editable:false,
		sort:true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options: [...new Set(lmes.map(item => item.category))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by Category',
			className:'column-filter'
		  })
	  },{
		dataField:'gender',
		text:'Gender',
		editable:true,
		sort:true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options: [...new Set(lmes.map(item => item.gender))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by Gender',
			className:'column-filter'
		  })
	  },{
		dataField:'cluster',
		text:'Cluster',
		sort:true,
		editable: true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(lmes.map(item => item.cluster))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by cluster',
			className:'column-filter'
		  })
	  },{
		dataField:'county',
		text:'County',
		sort:true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(lmes.map(item => item.county))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by County',
			className:'column-filter'
		  })
	  },{
		dataField: 'sub_county',
		text: 'Sub County',
		editable: false
	  },{
		dataField: 'ward',
		text: 'Ward',
		editable: false
	  },{
		dataField: 'df2',
		isDummyField: true,
		text: 'Details',
		classes:'text-center',
		headerClasses:'text-center',
		editable: false,
		csvExport: false,
		formatter: (cellContent, row) => {
		  
			return (
			  
				<button onClick={()=> navigate(`/lme/?id=${row._id}`)} className='button csv'> <BiDetail /> </button>
			  
			);
		  
		}},{
        dataField: 'df1',
        isDummyField: true,
        text: 'Delete',
        classes:'text-center',
        headerClasses:'text-center',
		csvExport: false,
        editable: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button onClick={()=>handleDelete(row._id, row.name)} className='delete'> <FaTrash /> </button>
              
            );
          
        }
      }];

	 

	  
	

	

	
  
  return (
	
	<div className="container px-6">
		
		
		<div className='flex p-9 pb-0 flex-row items-center justify-between'>
			<h1>Last Mile Enterprenuers</h1>
			
		</div>
		<div className="dash-card w-full min-h-[500px]">
			{response && <Alert variant={'success'}>{response}</Alert>}
			{loadingLMEs || loadingUpdate || loadingDelete ?<Loader fullscreen /> :errorLMEs || errorUpdate || errorDelete? <Alert variant={'error'}>{errorLMEs || errorUpdate || errorDelete}</Alert>:''}
			
					
					<div className="table-wrapper">
						
						{(!errorLMEs && !loadingLMEs) && <ToolkitProvider
                            search
                            selectFilter
                            keyField="_id"
                            data={ lmes }
                            columns={ columns }
							exportCSV={ {
                                fileName: 'LMEs.csv',
                              } }
                            
                            >
						{
							props => (
							<div>
								<div className='flex-wrap flex justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-green-500">
										LMEs
									</div>
									<div className='flex flex-row space-x-2'>
										<button className="button" onClick={()=>dispatch(listLmes())}>Refresh</button>
										<button className='button' disabled={changes.length===0} onClick={()=>dispatch(updateLme(changes))}>Save Changes</button>
										<ExportCSVButton { ...props.csvProps } className="py-2 px-3 button csv bg-green-500 rounded-lg" > <SiMicrosoftexcel /> </ExportCSVButton>
										<SearchBar srText={''} className='w-32' { ...props.searchProps } />
									</div>
									
								</div>
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-0 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() } 
								pagination={ paginationFactory({sizePerPage:50, sizePerPageList: [{ text: '50', value: 50 }, { text: '100', value: 100 }, { text: '500', value: 500 }, { text: '1000', value: 1000 }, { text: 'All', value: lmes.length } ]}) }
								cellEdit={ cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: (oldValue, newValue, row, column) => { setChanges([...changes, {value: newValue, _id: row._id, dataField: column.dataField}]); }
									}) } 
								/>
							</div>
							)
						}
						</ToolkitProvider>}
						
					</div>
		</div>
			
	</div>
  )
}

export default LMEsScreen