export const CUSTOMER_LIST_REQUEST = 'CUSTOMER_LIST_REQUEST'
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS'
export const CUSTOMER_LIST_FAIL = 'CUSTOMER_LIST_FAIL'

export const CUSTOMER_CREATE_REQUEST = 'CUSTOMER_CREATE_REQUEST'
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS'
export const CUSTOMER_CREATE_FAIL = 'CUSTOMER_CREATE_FAIL'
export const CUSTOMER_CREATE_RESET = 'CUSTOMER_CREATE_RESET'

export const CUSTOMER_UPDATE_REQUEST = 'CUSTOMER_UPDATE_REQUEST'
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS'
export const CUSTOMER_UPDATE_FAIL = 'CUSTOMER_UPDATE_FAIL'
export const CUSTOMER_UPDATE_RESET = 'CUSTOMER_UPDATE_RESET'

export const CUSTOMER_DELETE_REQUEST = 'CUSTOMER_DELETE_REQUEST'
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS'
export const CUSTOMER_DELETE_FAIL = 'CUSTOMER_DELETE_FAIL'
export const CUSTOMER_DELETE_RESET = 'CUSTOMER_DELETE_RESET'