import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
    userLoginReducer,
    // userRegisterReducer,
    // userDetailsReducer,
    // userUpdateProfileReducer,
    // userListReducer,
    // userDeleteReducer,
    // userUpdateReducer,
    // userEmailReducer,
} from './reducers/userReducers'
import {
    productListReducer,
    productCreateReducer,
    productUpdateReducer,
    productDeleteReducer

} from './reducers/productReducers'

import {
    monitorListReducer,
    monitorCreateReducer,
    monitorUpdateReducer,
    monitorDeleteReducer

} from './reducers/monitorReducers'

import {
    lmeListReducer,
    lmeCreateReducer,
    lmeUpdateReducer,
    lmeDeleteReducer,
    lmeImportReducer,
    lmeDetailsReducer,

} from './reducers/lmeReducers'

import {
    saleListReducer,
    saleCreateReducer,
    saleDeleteReducer,
    saleUpdateReducer,
    salesPerProductReducer,
    salesPerClusterReducer,
    salesPerLmetypeReducer,
    salesTrendReducer,
    productSalesTrendReducer,
    salesPerCountyReducer,
    saleImportReducer,
    saleAllReducer,
    salesPerLmeReducer,
    countyListReducer

} from './reducers/saleReducers'

import {
    customerListReducer,
    customerCreateReducer,
    customerUpdateReducer,
    customerDeleteReducer

} from './reducers/customerReducers'

import { countyDropdownReducer } from './reducers/dropdownReducers'

const userInfoFromStorage = sessionStorage.getItem('userInfo') ?
    JSON.parse(sessionStorage.getItem('userInfo')) : null

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
}

const reducer = combineReducers({
    userLogin: userLoginReducer,
    monitorList: monitorListReducer,    
    monitorCreate: monitorCreateReducer,
    monitorUpdate: monitorUpdateReducer,
    monitorDelete: monitorDeleteReducer,

    productList: productListReducer,    
    productCreate: productCreateReducer,
    productUpdate: productUpdateReducer,
    productDelete: productDeleteReducer,

    lmeList: lmeListReducer,    
    lmeCreate: lmeCreateReducer,
    lmeUpdate: lmeUpdateReducer,
    lmeDelete: lmeDeleteReducer,
    lmeImport: lmeImportReducer,
    lmeDetails: lmeDetailsReducer,

    countyList: countyListReducer,

    saleList: saleListReducer,
    saleCreate: saleCreateReducer,
    saleImport: saleImportReducer,
    salesTrend: salesTrendReducer,
    salesPerProduct: salesPerProductReducer,
    productSalesTrend: productSalesTrendReducer,
    saleUpdate: saleUpdateReducer,
    saleDelete: saleDeleteReducer,
    salesPerCluster: salesPerClusterReducer,
    salesPerLme: salesPerLmeReducer,
    salesPerLmetype: salesPerLmetypeReducer,
    salesPerCounty: salesPerCountyReducer,
    allSales: saleAllReducer,
    

    customerList: customerListReducer,    
    customerCreate: customerCreateReducer,
    customerUpdate: customerUpdateReducer,
    customerDelete: customerDeleteReducer,
    
    countyDropdown: countyDropdownReducer,
})
    
const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store
