import React from 'react'
import { Link } from 'react-router-dom'


function Paginate({ pages, page, screen}) {
    

    return (pages > 1 && (
        
            <div className="flex flex-row justify-end w-full mt-8 px-4">
                
                {page>1 && <Link className="button mr-auto"to={{pathname: `/${screen}`,search: `?page=${page - 1}`, }}>Previous</Link>}
                {page<pages && <Link className="button ml-auto"to={{pathname: `/${screen}`,search: `?page=${page + 1}`, }}>Next</Link>}
                {page>=pages && <div className='ml-auto'></div>}
            </div>
            
            
        
    )
    )
}

export default Paginate