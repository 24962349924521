import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getCountyDropdown } from '../actions/dropdownActions'
import OutsideAlerter from "../functions/listen-for-outside-clicks";
import PhoneInput from 'react-phone-input-2'
import { createSale } from '../actions/saleActions';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import { listProducts } from '../actions/productActions';
import { listLmes } from '../actions/lmeActions';
function SaleForm({saleOpen, setSaleOpen}) {
    const dispatch = useDispatch()
	const countyDropdown = useSelector(state=> state.countyDropdown)
	const {wards, counties, loading:loadingCounties, error:errorCounties} = countyDropdown

    const saleCreate = useSelector(state=> state.saleCreate)
	const {success, loading:loadingCreate, error:errorCreate} = saleCreate

    const productList = useSelector(state=> state.productList)
    const {error:errorProducts, loading:loadingProducts, products} = productList

    const lmeList = useSelector(state=> state.lmeList)
	const {loading: loadingLMEs, error: errorLMEs, lmes} = lmeList

	const [county, setCounty] = useState('')
    const [LME, setLME] = useState('')
	const [subCounty, setSubCounty] = useState('')
	const [ward, setWard] = useState('')
	const [customerName, setCustomerName] = useState('')
	const [quantity, setQuantity] = useState(1)
    const [unitPrice, setUnitPrice] = useState(1)
    const [totalPrice, settotalPrice] = useState(1)
    const [households, setHouseholds] = useState(1)
	const [customerType, setCustomerType] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [product, setProduct] = useState('')
	const [date, setDate] = useState('')
    const [gender, setGender] = useState('')
	
    

    const handleCountyChange = (newValue, actionMeta) => {
        

        if (newValue){
            setCounty(newValue.value)
        }else{
            setCounty('')
        }
        
    };

    const handleProductChange = (newValue, actionMeta) => {
        

        if (newValue){
            setProduct(newValue.value)
        }else{
            setProduct('')
        }
        
    };

    const handleLMEChange = (newValue, actionMeta) => {
        

        if (newValue){
            setLME(newValue.value)
        }else{
            setLME('')
        }
        
    };

	const handleSubCountyChange = (newValue, actionMeta) => {
        

        if (newValue){
            setSubCounty(newValue.value)
        }else{
            setSubCounty('')
        }
        
    };

	const handleWardChange = (newValue, actionMeta) => {
        

        if (newValue){
            setWard(newValue.value)
        }else{
            setWard('')
        }
        
    };

	const SaleSubmitHandler = (e)=>{
		e.preventDefault()
        dispatch(createSale({county, gender,subCounty, ward, customerName, customerType, phoneNumber, date, quantity, product, LME, households, unitPrice, totalPrice:unitPrice*quantity}))
		
	} 
	
	useEffect(() => {
	  dispatch(getCountyDropdown())
      dispatch(listProducts())
      dispatch(listLmes())

      if (success) {
        setDate('')
        setQuantity(1)
        setCustomerName('')
        setCounty('')
        setSubCounty('')
        setWard('')
        setCustomerType('')
        setPhoneNumber('')
        setSaleOpen(false)
        setProduct('')
        setUnitPrice(1)
        settotalPrice(1)
        setGender('')
      }
      
	}, [dispatch, success, setSaleOpen])
	
	const selectedSubCounties = wards.filter(n=>n.county===county)
	const selectedWards = wards.filter(n=>n.sub_county===subCounty)
  return (
    <div className={`modal-container ${saleOpen ? 'flex' : 'hidden'}`}>
            {saleOpen && <OutsideAlerter setOpen={setSaleOpen}>
			    <div className="modal-content">
				<h2 className="modal-header">
					Add Sale
				</h2>
				<div className="modal-body">
					<form className='flex flex-wrap' onSubmit={SaleSubmitHandler}>
                        <div className="col-2">	
                            <div className="form-group">
                                <label htmlFor="date" className="form-label">Date</label>
                                <input value={date} onChange={(e)=> setDate(e.target.value)} required type="date" name="date" id="date" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="lme" className="form-label">LME</label>
                                <Select id='lme' required isClearable isSearchable options={lmes.map((item)=> { return {label:item.name, value:item._id}} )} onChange={handleLMEChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="product" className="form-label">Product</label>
                                <Select id='product' required isClearable isSearchable options={products.map((item)=> { return {label:item.name, value:item.name}} )} onChange={handleProductChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="quantity" className="form-label">Quantity</label>
                                <input value={quantity} onChange={(e)=> setQuantity(e.target.value)} required type="number" placeholder='Please Enter Quantity' name="quantity" id="quantity" className="form-input" />
                            </div>
                            

                            <div className="form-group">
                                <label htmlFor="unitPrice" className="form-label">Unit Price</label>
                                <input value={unitPrice} onChange={(e)=> setUnitPrice(e.target.value)} required type="number" placeholder='Please Price' name="unitPrice" id="unitPrice" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="totalPrice" className="form-label">Total Price</label>
                                <input value={unitPrice*quantity} onChange={(e)=> settotalPrice(e.target.value)} disabled required type="number"  name="totalPrice" id="totalPrice" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="households" className="form-label">Households Served</label>
                                <input value={households} onChange={(e)=> setHouseholds(e.target.value)} required type="number" placeholder='Please Enter Customer Name' name="households" id="households" className="form-input" />
                            </div>

                            
                            
                            

                        </div>



                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="customerName" className="form-label">Customer Name</label>
                                <input value={customerName}  onChange={(e)=> setCustomerName(e.target.value)} required type="text" placeholder='Please Enter Customer Name' name="customerName" id="customerName" className="form-input" />
                            </div>

                            

                            <div className="form-group">
                                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                <PhoneInput
                                    onlyCountries={['ke','ug','sn']}
                                    country='ke'
                                    value={phoneNumber}
                                    onChange={setPhoneNumber} 
                                    
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="gender" className="form-label">Gender</label>
                                <select value={gender} onChange={(e)=> setGender(e.target.value)} required  name="gender" id="gender" className="form-input" >
                                    <option value=''>--Select Gender--</option>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                </select>
                            </div>

                            
                            

                            <div className="form-group">
                                <label htmlFor="county" className="form-label">County</label>
                                <Select required isClearable isSearchable options={counties} onChange={handleCountyChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="subCounty" className="form-label">Sub County</label>
                                <Select isDisabled={county === ''} isClearable isSearchable options={[...new Set (selectedSubCounties.map(c => c.sub_county))].map((x)=> { return {value:x, label:x}} )} onChange={handleSubCountyChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="ward" className="form-label">Ward</label>
                                <Select isDisabled={subCounty === ''} isClearable isSearchable options={selectedWards.map((x)=> { return {value:x.ward, label:x.ward}} )} onChange={handleWardChange} />
                            </div>
                            
                        </div>

						

						
						
						<div className="w-full px-6 hover:scale-101">
							<button type="submit" className='form-submit'>Save</button>
						</div>

                        {(errorCreate|| errorProducts || errorCounties || errorLMEs) && <Alert variant='error' >{errorCreate|| errorProducts || errorCounties || errorLMEs}</Alert>}
						
					</form>
				</div>
			    </div>
            </OutsideAlerter>}
            {(loadingCreate || loadingProducts || loadingCounties|| loadingLMEs) && <Loader fullscreen />}
		</div>
  )
}

export default SaleForm