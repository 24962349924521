export const MONITOR_LIST_REQUEST = 'MONITOR_LIST_REQUEST'
export const MONITOR_LIST_SUCCESS = 'MONITOR_LIST_SUCCESS'
export const MONITOR_LIST_FAIL = 'MONITOR_LIST_FAIL'


export const MONITOR_DETAILS_REQUEST = 'MONITOR_DETAILS_REQUEST'
export const MONITOR_DETAILS_SUCCESS = 'MONITOR_DETAILS_SUCCESS'
export const MONITOR_DETAILS_FAIL = 'MONITOR_DETAILS_FAIL'


export const MONITOR_DELETE_REQUEST = 'MONITOR_DELETE_REQUEST'
export const MONITOR_DELETE_SUCCESS = 'MONITOR_DELETE_SUCCESS'
export const MONITOR_DELETE_FAIL = 'MONITOR_DELETE_FAIL'

export const MONITOR_CREATE_REQUEST = 'MONITOR_CREATE_REQUEST'
export const MONITOR_CREATE_SUCCESS = 'MONITOR_CREATE_SUCCESS'
export const MONITOR_CREATE_FAIL = 'MONITOR_CREATE_FAIL'
export const MONITOR_CREATE_RESET = 'MONITOR_CREATE_RESET'


export const MONITOR_UPDATE_REQUEST = 'MONITOR_UPDATE_REQUEST'
export const MONITOR_UPDATE_SUCCESS = 'MONITOR_UPDATE_SUCCESS'
export const MONITOR_UPDATE_FAIL = 'MONITOR_UPDATE_FAIL'
export const MONITOR_UPDATE_RESET = 'MONITOR_UPDATE_RESET'



