import {
    SALE_ALL_REQUEST,
    SALE_ALL_SUCCESS,
    SALE_ALL_FAIL,

    SALE_LIST_REQUEST,
    SALE_LIST_SUCCESS,
    SALE_LIST_FAIL,

    SALE_CREATE_REQUEST,
    SALE_CREATE_SUCCESS,
    SALE_CREATE_FAIL,

    SALES_TREND_REQUEST,
    SALES_TREND_SUCCESS,
    SALES_TREND_FAIL,

    SALES_PER_PRODUCT_REQUEST,
    SALES_PER_PRODUCT_SUCCESS,
    SALES_PER_PRODUCT_FAIL,

    PRODUCT_SALES_TREND_REQUEST,
    PRODUCT_SALES_TREND_SUCCESS,
    PRODUCT_SALES_TREND_FAIL,

    SALE_UPDATE_REQUEST,
    SALE_UPDATE_SUCCESS,
    SALE_UPDATE_FAIL,

    SALE_DELETE_REQUEST,
    SALE_DELETE_SUCCESS,
    SALE_DELETE_FAIL,

    SALES_PER_CLUSTER_REQUEST,
    SALES_PER_CLUSTER_SUCCESS,
    SALES_PER_CLUSTER_FAIL,

    SALES_PER_COUNTY_REQUEST,
    SALES_PER_COUNTY_SUCCESS,
    SALES_PER_COUNTY_FAIL,

    SALES_PER_LMETYPE_REQUEST,
    SALES_PER_LMETYPE_SUCCESS,
    SALES_PER_LMETYPE_FAIL,

    SALES_PER_LME_REQUEST,
    SALES_PER_LME_SUCCESS,
    SALES_PER_LME_FAIL,

    SALE_IMPORT_REQUEST,
    SALE_IMPORT_SUCCESS,
    SALE_IMPORT_FAIL,
    COUNTY_LIST_REQUEST,
    COUNTY_LIST_SUCCESS,
    COUNTY_LIST_FAIL,
    

} from '../constants/saleConstants'

import axios from 'axios'

export const listSales = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({ type: SALE_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        

        const { data } = await axios.post(`/api/sales/?${keyword}`,{}, config)

        

        dispatch({
            type: SALE_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SALE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listAllSales = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SALE_ALL_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        

        const { data } = await axios.post(`/api/sales/all/`,{}, config)

        

        dispatch({
            type: SALE_ALL_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SALE_ALL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createSale = (sale) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SALE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/sales/create/`,
            sale,
            config
        )
        dispatch({
            type: SALE_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: SALE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSalesTrend = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SALES_TREND_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/sales/trend/`, config)

        dispatch({
            type: SALES_TREND_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SALES_TREND_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSalesPerProduct= () => async (dispatch, getState) => {
    try {
        dispatch({ type: SALES_PER_PRODUCT_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/sales/product/`, config)

        dispatch({
            type: SALES_PER_PRODUCT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SALES_PER_PRODUCT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getProductSalesTrend = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PRODUCT_SALES_TREND_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/sales/product/trend/`, config)

        dispatch({
            type: PRODUCT_SALES_TREND_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_SALES_TREND_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateSale = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SALE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const  { data }  = await axios.post(
            `/api/sales/update/`,
            changes,
            config
        )
        dispatch({
            type: SALE_UPDATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: SALE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteSale = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SALE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/sales/delete/${id}/`,
            {},
            config
        )

        dispatch({
            type: SALE_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SALE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSalesPerCounty= () => async (dispatch, getState) => {
    try {
        dispatch({ type: SALES_PER_COUNTY_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/sales/county/`, config)

        dispatch({
            type: SALES_PER_COUNTY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SALES_PER_COUNTY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSalesPerCluster= () => async (dispatch, getState) => {
    try {
        dispatch({ type: SALES_PER_CLUSTER_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/sales/cluster/`, config)

        dispatch({
            type: SALES_PER_CLUSTER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SALES_PER_CLUSTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSalesPerLme= () => async (dispatch, getState) => {
    try {
        dispatch({ type: SALES_PER_LME_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/sales/lme/`, config)

        dispatch({
            type: SALES_PER_LME_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SALES_PER_LME_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getSalesPerLmetype= () => async (dispatch, getState) => {
    try {
        dispatch({ type: SALES_PER_LMETYPE_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/sales/lmetype/`, config)

        dispatch({
            type: SALES_PER_LMETYPE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SALES_PER_LMETYPE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const importSales = (sales) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SALE_IMPORT_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/sales/import/`,
            sales,
            config
        )
        
        dispatch({
            type: SALE_IMPORT_SUCCESS,
            payload: data,
        })
        
        




    } catch (error) {
        dispatch({
            type: SALE_IMPORT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listCounties = () => async (dispatch, getState) => {
    try {
        dispatch({ type: COUNTY_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        

        const { data } = await axios.get(`/api/sales/counties/`, config)

        

        dispatch({
            type: COUNTY_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: COUNTY_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}