import {
    LME_LIST_REQUEST,
    LME_LIST_SUCCESS,
    LME_LIST_FAIL,

    LME_DETAILS_REQUEST,
    LME_DETAILS_SUCCESS,
    LME_DETAILS_FAIL,

    LME_DELETE_REQUEST,
    LME_DELETE_SUCCESS,
    LME_DELETE_FAIL,
    LME_DELETE_RESET,

    LME_CREATE_REQUEST,
    LME_CREATE_SUCCESS,
    LME_CREATE_FAIL,
    LME_CREATE_RESET,

    LME_UPDATE_REQUEST,
    LME_UPDATE_SUCCESS,
    LME_UPDATE_FAIL,
    LME_UPDATE_RESET,
    LME_IMPORT_REQUEST,
    LME_IMPORT_SUCCESS,
    LME_IMPORT_FAIL,
    LME_IMPORT_RESET,

} from '../constants/lmeConstants'


export const lmeListReducer = (state = { lmes: [], distributors:[], stockists:[], producers:[], installers:[], selectOptions: [] }, action) => {
    switch (action.type) {
        case LME_LIST_REQUEST:
            return { loading: true, lmes: [], distributors:[], stockists:[], producers:[], installers:[],selectOptions: [] }

        case LME_LIST_SUCCESS:
            const stockists = action.payload.filter(l=>l.category=='Stockist')
            const producers = action.payload.filter(item=>item.category=='Producer')
            const installers = action.payload.filter(l=>l.category=='Installer')
            const distributors = action.payload.filter(l=>l.category=='Distributor')
            
            const countyArray = [...new Set(action.payload.map(item => item.county))];
            return {
                loading: false,
                lmes: action.payload,
                selectOptions: countyArray.map((item)=> { return {label:item, value:item}} ),
                stockists,
                producers,
                installers,
                distributors
            }

        case LME_LIST_FAIL:
            return { loading: false, error: action.payload, distributors:[], stockists:[], producers:[], installers:[], selectOptions: [] }

        default:
            return state
    }
}



export const lmeDetailsReducer = (state = { lme: {}, products: [], customers: [], sales: [], customers: [], sales_per_product:[], product_sales_trend: [], sales_trend:[] }, action) => {
    switch (action.type) {
        case LME_DETAILS_REQUEST:
            return { loading: true, ...state, customers: [], products:[]}

        case LME_DETAILS_SUCCESS:
            return { loading: false, 
                    lme: action.payload, 
                    sales: action.payload.sales, 
                    products: [...new Set(action.payload?.sales?.map(item => item.product))], 
                    customers: action.payload.customers,
                    sales_per_product: action.payload.sales_per_product,
                    product_sales_trend: action.payload.product_sales_trend,
                    sales_trend: action.payload.sales_trend, 
                }

        case LME_DETAILS_FAIL:
            return { loading: false, error: action.payload, customers: [], products:[] }

        default:
            return state
    }
}
export const lmeCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case LME_CREATE_REQUEST:
            return { loading: true }

        case LME_CREATE_SUCCESS:
            return { loading: false, success: true, lme: action.payload }

        case LME_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case LME_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const lmeImportReducer = (state = {importedLmes:[]}, action) => {
    switch (action.type) {
        case LME_IMPORT_REQUEST:
            return { loading: true, importedLmes:[] }

        case LME_IMPORT_SUCCESS:
            return { loading: false, success: true, importedLmes: action.payload }

        case LME_IMPORT_FAIL:
            return { loading: false, error: action.payload, importedLmes:[] }

        case LME_IMPORT_RESET:
            return {}

        default:
            return state
    }
}


export const lmeDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case LME_DELETE_REQUEST:
            return { loading: true }

        case LME_DELETE_SUCCESS:
            return { loading: false, success: true }

        case LME_DELETE_FAIL:
            return { loading: false, error: action.payload }
        
        case LME_DELETE_RESET:
            return {}

        default:
            return state
    }
}


export const lmeUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case LME_UPDATE_REQUEST:
            return { loading: true }

        case LME_UPDATE_SUCCESS:
            return { loading: false, success: true, response: action.payload }

        case LME_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case LME_UPDATE_RESET:
            return { state: {} }

        default:
            return state
    }
}


