import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { MdLogout } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'
function Header({setCurrentLocation, currentLocation}) {
  const dispatch = useDispatch()
  const location = useLocation()
  const userLogin = useSelector(state => state.userLogin)
  const { loading, error, userInfo } = userLogin
  useEffect(() => {
    setCurrentLocation(location.pathname)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  return (
    <div className={`${currentLocation==='/' ? 'fixed ml-0 md:-ml-60 py-8' :''} w-full px-6 py-4 items-center bg-white shadow-lg flex flex-row justify-end`}>
        {(!loading && !error && userInfo) && <span className="text-sm">{userInfo.username}</span>}
        {currentLocation!=='/' && <div onClick={()=>dispatch(logout())} className='flex flex-row items-center ml-auto cursor-pointer'>
          <MdLogout className='mr-2' />
          <span>Logout</span>
        </div>}
        
        

    </div>
  )
}

export default Header