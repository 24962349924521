import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getCountyDropdown } from '../actions/dropdownActions'
import OutsideAlerter from "../functions/listen-for-outside-clicks";
import PhoneInput from 'react-phone-input-2'
import { createMonitor } from '../actions/monitorActions';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
function MonitorForm({monitorOpen, setMonitorOpen}) {
    const dispatch = useDispatch()
	const countyDropdown = useSelector(state=> state.countyDropdown)
	const {wards, counties, loading:loadingCounties, error:errorCounties} = countyDropdown

    const monitorCreate = useSelector(state=> state.monitorCreate)
	const {success, loading:loadingCreate, error:errorCreate} = monitorCreate

	const [county, setCounty] = useState('')
    const [subCounty, setSubCounty] = useState('')
	const [monitorName, setMonitorName] = useState('')
	const [monitorCategory, setMonitorCategory] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
    const [gender, setGender] = useState('')
	
    

    const handleCountyChange = (newValue, actionMeta) => {
        

        if (newValue){
            setCounty(newValue.value)
        }else{
            setCounty('')
        }
        
    };
	const handleSubCountyChange = (newValue, actionMeta) => {
        

        if (newValue){
            setSubCounty(newValue.value)
        }else{
            setSubCounty('')
        }
        
    };

	

	const monitorSubmitHandler = (e)=>{
		e.preventDefault()
        dispatch(createMonitor({county, monitorName, monitorCategory, phoneNumber, gender}))
		
	} 
	
	useEffect(() => {
	  dispatch(getCountyDropdown())

      if (success) {
        
        setMonitorCategory('')
        setMonitorName('')
        setCounty('')
        setSubCounty('')
        setPhoneNumber('')
        setMonitorOpen(false)
        setGender('')
      }
      
	}, [dispatch, success])
	
	const selectedSubCounties = wards.filter(n=>n.county==county)
	
  return (
    <div className={`modal-container ${monitorOpen ? 'flex' : 'hidden'}`}>
            {monitorOpen && <OutsideAlerter setOpen={setMonitorOpen}>
			    <div className="modal-content small">
				<h2 className="modal-header">
					Add monitor
				</h2>
				<div className="modal-body">
					<form className='flex flex-wrap' onSubmit={monitorSubmitHandler}>
                        						
                            <div className="form-group">
                                <label htmlFor="monitorName" className="form-label">Name</label>
                                <input value={monitorName} onChange={(e)=> setMonitorName(e.target.value)} required type="text" placeholder='Please Enter monitor Name' name="monitorName" id="monitorName" className="form-input" />
                            </div>


                            {/* <div className="form-group">
                                <label htmlFor="monitorCategory" className="form-label">Category</label>
                                <select value={monitorCategory} onChange={(e)=> setMonitorCategory(e.target.value)} required  name="monitorCategory" id="monitorCategory" className="form-input" >
                                    <option value=''>--Select monitor Category--</option>
                                    <option value='Producer'>Producer</option>
                                    <option value='Distributor'>Distributor</option>
                                    <option value='Stockist'>Stockist</option>
                                    <option value='Installer'>Installer</option>
                                </select>
                            </div> */}

                            <div className="form-group">
                                <label htmlFor="phoneNumber" className="form-label">Phone Number </label>
                                <PhoneInput
                                    onlyCountries={['ke','ug','sn']}
                                    country='ke'
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    inputClass='form-input'
                                    placeholder='+254 712 345 678'
                                    copyNumbersOnly={false}
                                    countryCodeEditable={false}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="gender" className="form-label">Gender</label>
                                <select value={gender} onChange={(e)=> setGender(e.target.value)} required  name="gender" id="gender" className="form-input" >
                                    <option value=''>--Select Gender--</option>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                </select>
                            </div>
                       
                            <div className="form-group">
                                <label htmlFor="county" className="form-label">County</label>
                                <Select isClearable isSearchable options={counties} onChange={handleCountyChange} />
                            </div>

                            {/* <div className="form-group">
                                <label htmlFor="subCounty" className="form-label">Sub County</label>
                                <Select isDisabled={county === ''} isClearable isSearchable options={[...new Set (selectedSubCounties.map(c => c.sub_county))].map((x)=> { return {value:x, label:x}} )} onChange={handleSubCountyChange} />
                            </div> */}
                            
                            <div className="w-full px-6 hover:scale-101">
                                <button type="submit" className='form-submit'>Save</button>
                            </div>

                        {errorCreate && <Alert variant='error' >{errorCreate}</Alert>}
						
					</form>
				</div>
			    </div>
            </OutsideAlerter>}
            {loadingCreate && <Loader fullscreen />}
		</div>
  )
}

export default MonitorForm