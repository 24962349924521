import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getCountyDropdown } from '../actions/dropdownActions'
import OutsideAlerter from "../functions/listen-for-outside-clicks";
import PhoneInput from 'react-phone-input-2'
import { createLme } from '../actions/lmeActions';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
function LMEForm({LMEOpen, setLMEOpen}) {
    const dispatch = useDispatch()
	const countyDropdown = useSelector(state=> state.countyDropdown)
	const {wards, counties, loading:loadingCounties, error:errorCounties} = countyDropdown

    const lmeCreate = useSelector(state=> state.lmeCreate)
	const {success, loading:loadingCreate, error:errorCreate} = lmeCreate

    const monitorList = useSelector(state=> state.monitorList)
	const {loading: loadingMonitors, error: errorMonitors, monitors} = monitorList


	const [county, setCounty] = useState('')
	const [subCounty, setSubCounty] = useState('')
	const [ward, setWard] = useState('')
	const [LMEName, setLMEName] = useState('')
	const [LMECategory, setLMECategory] = useState('')
	const [LMEType, setLMEType] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [monitor, setMonitor] = useState('')
	const [contactPersonName, setContactPersonName] = useState('')
	const [contactPersonPhone, setContactPersonPhone] = useState('')
    const [gender, setGender] = useState('')
    const [groupAffiliation, setGroupAffiliation] = useState('')
    

    const handleCountyChange = (newValue, actionMeta) => {
        

        if (newValue){
            setCounty(newValue.value)
        }else{
            setCounty('')
        }
        
    };
	const handleSubCountyChange = (newValue, actionMeta) => {
        

        if (newValue){
            setSubCounty(newValue.value)
        }else{
            setSubCounty('')
        }
        
    };

	const handleWardChange = (newValue, actionMeta) => {
        

        if (newValue){
            setWard(newValue.value)
        }else{
            setWard('')
        }
        
    };

    const handleMonitorChange = (newValue, actionMeta) => {
        

        if (newValue){
            setMonitor(newValue.value)
        }else{
            setMonitor('')
        }
        
    };

	const LMESubmitHandler = (e)=>{
		e.preventDefault()
        dispatch(createLme({county, gender,ward, LMEName, LMECategory, LMEType, phoneNumber, contactPersonName, contactPersonPhone, monitor, groupAffiliation, subCounty}))
		
	} 
	
	useEffect(() => {
	  dispatch(getCountyDropdown())

      if (success) {
        setContactPersonName('')
        setContactPersonPhone('')
        setLMECategory('')
        setLMEName('')
        setCounty('')
        setSubCounty('')
        setWard('')
        setLMEType('')
        setPhoneNumber('')
        setLMEOpen(false)
        setGender('')
        setGroupAffiliation('')
      }
      
	}, [dispatch, success])
	
	const selectedSubCounties = wards.filter(n=>n.county==county)
	const selectedWards = wards.filter(n=>n.sub_county==subCounty)
  return (
    <div className={`modal-container ${LMEOpen ? 'flex' : 'hidden'}`}>
            {LMEOpen && <OutsideAlerter setOpen={setLMEOpen}>
			    <div className="modal-content">
				<h2 className="modal-header">
					Add LME
				</h2>
				<div className="modal-body">
					<form className='flex flex-wrap' onSubmit={LMESubmitHandler}>
                        <div className="col-2">							
                            <div className="form-group">
                                <label htmlFor="LMEName" className="form-label">Name</label>
                                <input value={LMEName} onChange={(e)=> setLMEName(e.target.value)} required type="text" placeholder='Please Enter LME Name' name="LMEName" id="LMEName" className="form-input" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="LMEType" className="form-label">Type</label>
                                <select value={LMEType} onChange={(e)=> setLMEType(e.target.value)} required  name="LMEType" id="LMEType" className="form-input" >
                                    <option value=''>--Select LME Type--</option>
                                    <option value='Individual'>Individual</option>
                                    <option value='Group'>Group</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="LMECategory" className="form-label">Category</label>
                                <select value={LMECategory} onChange={(e)=> setLMECategory(e.target.value)} required  name="LMECategory" id="LMECategory" className="form-input" >
                                    <option value=''>--Select LME Category--</option>
                                    <option value='Producer'>Producer</option>
                                    <option value='Distributor'>Distributor</option>
                                    <option value='Stockist'>Stockist</option>
                                    <option value='Installer'>Installer</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="gender" className="form-label">Gender</label>
                                <select value={gender} onChange={(e)=> setGender(e.target.value)} required  name="gender" id="gender" className="form-input" >
                                    <option value=''>--Select Gender--</option>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                </select>
                            </div>

                            {LMECategory==='Producer' && <div className="form-group">
                                <label htmlFor="contactPersonName" className="form-label">Contact Person Name</label>
                                <input value={contactPersonName} onChange={(e)=> setContactPersonName(e.target.value)} required type="text" placeholder='Please Enter Name' name="contactPersonName" id="contactPersonName" className="form-input" />
                            </div>}

                            <div className="form-group">
                                <label htmlFor="phoneNumber" className="form-label">Phone Number </label>
                                <PhoneInput
                                    onlyCountries={['ke','ug','sn']}
                                    country='ke'
                                    value={phoneNumber}
                                    onChange={setPhoneNumber} 
                                />
                            </div>


                            

                            
                        </div>

                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="county" className="form-label">County</label>
                                <Select isClearable isSearchable options={counties} onChange={handleCountyChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="subCounty" className="form-label">Sub County</label>
                                <Select isDisabled={county === ''} isClearable isSearchable options={[...new Set (selectedSubCounties.map(c => c.sub_county))].map((x)=> { return {value:x, label:x}} )} onChange={handleSubCountyChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="ward" className="form-label">Ward</label>
                                <Select isDisabled={subCounty === ''} isClearable isSearchable options={selectedWards.map((x)=> { return {value:x.ward, label:x.ward}} )} onChange={handleWardChange} />
                            </div>

                            {(!loadingMonitors && !errorMonitors) && <div className="form-group">
                                <label htmlFor="monitor" className="form-label">Monitor</label>
                                <Select id='monitor' required isClearable isSearchable options={monitors.map((item)=> { return {label:item.name, value:item._id}} )} onChange={handleMonitorChange} />
                            </div>}
                        </div>

						

						
						
						<div className="w-full px-6 hover:scale-101">
							<button type="submit" className='form-submit'>Save</button>
						</div>

                        {(errorCreate || errorMonitors ) && <Alert variant='error' >{errorCreate || errorMonitors}</Alert>}
						
					</form>
				</div>
			    </div>
            </OutsideAlerter>}
            {(loadingCreate || loadingMonitors) && <Loader fullscreen />}
		</div>
  )
}

export default LMEForm