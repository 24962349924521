export const LME_LIST_REQUEST = 'LME_LIST_REQUEST'
export const LME_LIST_SUCCESS = 'LME_LIST_SUCCESS'
export const LME_LIST_FAIL = 'LME_LIST_FAIL'


export const LME_DETAILS_REQUEST = 'LME_DETAILS_REQUEST'
export const LME_DETAILS_SUCCESS = 'LME_DETAILS_SUCCESS'
export const LME_DETAILS_FAIL = 'LME_DETAILS_FAIL'


export const LME_DELETE_REQUEST = 'LME_DELETE_REQUEST'
export const LME_DELETE_SUCCESS = 'LME_DELETE_SUCCESS'
export const LME_DELETE_FAIL = 'LME_DELETE_FAIL'
export const LME_DELETE_RESET = 'LME_DELETE_RESET'

export const LME_CREATE_REQUEST = 'LME_CREATE_REQUEST'
export const LME_CREATE_SUCCESS = 'LME_CREATE_SUCCESS'
export const LME_CREATE_FAIL = 'LME_CREATE_FAIL'
export const LME_CREATE_RESET = 'LME_CREATE_RESET'


export const LME_UPDATE_REQUEST = 'LME_UPDATE_REQUEST'
export const LME_UPDATE_SUCCESS = 'LME_UPDATE_SUCCESS'
export const LME_UPDATE_FAIL = 'LME_UPDATE_FAIL'
export const LME_UPDATE_RESET = 'LME_UPDATE_RESET'

export const LME_IMPORT_REQUEST = 'LME_IMPORT_REQUEST'
export const LME_IMPORT_SUCCESS = 'LME_IMPORT_SUCCESS'
export const LME_IMPORT_FAIL = 'LME_IMPORT_FAIL'
export const LME_IMPORT_RESET = 'LME_IMPORT_RESET'