import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SaleForm from '../forms/SaleForm'
import { deleteSale, listSales, updateSale, importSales } from '../actions/saleActions';
import Alert from '../components/Alert';
import Loader from '../components/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import idFormatter from '../functions/idFormatter';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport, ColumnToggle} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { SiMicrosoftexcel } from 'react-icons/si'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { SALE_IMPORT_RESET, SALE_UPDATE_RESET } from '../constants/saleConstants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { listLmes } from '../actions/lmeActions';
import { listProducts } from '../actions/productActions';
import generateTemplte from '../functions/generateTemplte';
import { useToast } from '@chakra-ui/react'





function AddSalesScreen() {
	const toast = useToast()
	let formSales = []
	
	const [isOpen, setIsOpen] = useState(false)
    const [saleOpen, setSaleOpen] = useState(false)
	const [changes, setChanges] = useState([])
    const dispatch = useDispatch()
	const navigate = useNavigate()
	const onClose = () => {setIsOpen(false)};
  // Called after user completes the flow. Provides data array, where data keys matches your field keys.
  	const onSubmit = (data) => dispatch(importSales(data))

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

	

	const saleCreate = useSelector(state=> state.saleCreate)
	const {success, sale} = saleCreate

	const saleImport = useSelector(state=> state.saleImport)
	const {success: successImport, loading: loadingImport, error: errorImport, importedSales} = saleImport

	const saleUpdate = useSelector(state => state.saleUpdate)
    const { error: errorUpdate, loading: loadingUpdate, response, success:successUpdate} = saleUpdate

	const saleDelete = useSelector(state => state.saleDelete)
    const { error: errorDelete, loading: loadingDelete, success:successDelete} = saleDelete

	const lmeList = useSelector(state=> state.lmeList)
	const {loading: loadingLMEs, error: errorLMEs , lmes} = lmeList

	const productList = useSelector(state=> state.productList)
  	const {error:errorProducts, loading:loadingProducts, products} = productList

	const [searchParams, setSearchParams] = useSearchParams()

	

	

	



    

	useEffect(() => {
		window.scrollTo(0,0)

		if (successUpdate){
			setChanges([])
			setTimeout(() => {
				dispatch({type: SALE_UPDATE_RESET})
				
			}, 2000);
		}
		
		if(!userInfo){
            navigate('/')
        }else{
	  		
			dispatch(listLmes())
			dispatch(listProducts())
		}
		
	}, [dispatch, success, successUpdate, userInfo, successDelete, successImport])

    const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;
	

	const handleDelete = (id)=>{
        if (window.confirm(`Permanently Delete this Sale? This action is irreversible`)) {
            dispatch(deleteSale(id))
        }
    }

	
    
	

    const columns = [{
		dataField: '_id',
		text: '#',
		editable: false,
        formatter: idFormatter,
		csvExport: false
        
	  },{
		dataField:'date',
		text:'Date',
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField: 'lme_name',
		text: 'LME',
		sort: true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(importedSales.map(item => item.lme_name))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by LME',
			className:'column-filter'
		  })
		
		
	  },{
		dataField:'lme_category',
		text:'LME Type',
		sort:true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(importedSales.map(item => item.lme_category))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by LME Type',
			className:'column-filter'
		  })
        
	  },{
		dataField:'cluster',
		text:'Cluster',
		sort:true,
		editable: false,
		sortCaret: sortCaret,
        
	  },{
		dataField:'lme_county',
		text:'County',
		sort:true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(importedSales.map(item => item.county))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by County',
			className:'column-filter'
		  })
	  },{
		dataField:'lme_sub_county',
		text:'Sub County',
		hidden:true,
		editable: false,
		sortCaret: sortCaret,
		
	  },{
		dataField:'lme_ward',
		text:'Ward',
		hidden:true,
		editable: false,
		sortCaret: sortCaret,
		
	  },{
		dataField: 'customer_name',
		text: 'Customer Name',
		sort: true,
		editable: true,
		sortCaret: sortCaret,
		
		
	  },{
		dataField: 'customer_phone_number',
		text: 'Customer Phone',
		sort: true,
		editable: true,
		sortCaret: sortCaret,
		
		
	  },{
		dataField: 'customer_county',
		text: 'Customer County',
		hidden: true,
		editable: true,
		
	  },{
		dataField: 'customer_sub_county',
		text: 'Customer Sub County',
		hidden: true,
		editable: true,
		
	  },{
		dataField: 'customer_ward',
		text: 'Customer Ward',
		hidden: true,
		editable: true,
		
	  },{
		dataField: 'product',
		text: 'Product',
		sort: true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(importedSales.map(item => item.product))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by Product',
			className:'column-filter'
		  })
		
		
	  },{
		dataField:'quantity',
		text:'Quantity',
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField:'unitPrice',
		text:'Unit Price',
		sort:true,
		sortCaret: sortCaret,
		editable: true,
		hidden: true
        
	  },{
		dataField:'totalPrice',
		text:'Total Price',
		sort:true,
		sortCaret: sortCaret,
		editable: false,
		csvExport: false
        
	  },{
        dataField: 'df1',
        isDummyField: true,
        text: 'Delete',
        classes:'text-center',
        headerClasses:'text-center',
        editable: false,
		csvExport: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button onClick={()=>handleDelete(row._id)} className='delete'> <FaTrash /> </button>
              
            );
          
        }
      }];

	  const fields = [
		{
			// Visible in table header and when matching columns.
			label: "Date",
			// This is the key used for this field when we call onSubmit.
			key: "date",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["sale date", "date", 'date of sale', "sale date", "date", 'date of sale'],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			example: "January",
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Date of Sale is required",
				// There can be "info" / "warning" / "error" levels. Optional. Default "error".
				level: "error",
			  },
			]
		  },{
		  // Visible in table header and when matching columns.
		  label: "LME",
		  // This is the key used for this field when we call onSubmit.
		  key: "LME",
		  // Allows for better automatic column matching. Optional.
		  alternateMatches: ["LME", "LMEName", 'producer'],
		  // Used when editing and validating information.
		  fieldType: {
			type: "select",
			options: lmes.map(l=>{return {value:l._id, label:l.name}}),
		  },
		  validations: [
			{
			  // Can be "required" / "unique" / "regex"
			  rule: "required",
			  errorMessage: "LME Name is required",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  level: "error",
			},
		  ]
		},{
			// Visible in table header and when matching columns.
			label: "Product",
			// This is the key used for this field when we call onSubmit.
			key: "product",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["product", "productName", 'name of product', "item", "stove"],
			// Used when editing and validating information.
			fieldType: {
			  type: "select",
			  options:products.map(l=>{return {value:l.name, label:l.name}}),
			
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Product Name is required",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "Quantity",
			// This is the key used for this field when we call onSubmit.
			key: "quantity",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["number", "quantity", 'count', "items", "units"],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Quantity is required",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "Unit Price",
			// This is the key used for this field when we call onSubmit.
			key: "unitPrice",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["unit cost", "Unit Rrice","unit price", 'unitPrice', "price per item"],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Unit Price is required",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "required",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "Customer Name",
			// This is the key used for this field when we call onSubmit.
			key: "customerName",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["customer", "customerName", 'name of customer', "buyer", "buyer name"],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Customer Name is required",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "Customer Gender",
			// This is the key used for this field when we call onSubmit.
			key: "customerGender",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["gender", "customerGender", 'gender of customer', "sex", "buyer gender"],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Customer Gender is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "warning",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "Customer Contact",
			// This is the key used for this field when we call onSubmit.
			key: "customerPhone",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["customer number", "customer contact", "customerPhone", 'contact of customer', "phone number", "telephone", "phone", "tel", "contact"],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Customer Contact is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "warning",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "Customer County",
			// This is the key used for this field when we call onSubmit.
			key: "county",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["customer location", "location", 'county', "Customer County"],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "County is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "warning",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "Customer Sub County",
			// This is the key used for this field when we call onSubmit.
			key: "subCounty",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["customer sub county","Customer Sub County", "subcounty", 'sub-county', 'division', 'sub_county'],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Sub County is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "warning",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "Customer Ward",
			// This is the key used for this field when we call onSubmit.
			key: "ward",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["Customer Ward", "customer ward", "ward", 'sub-location', 'customerward', 'sub-location'],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Ward is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "warning",
			  },
			]
		  }
	  ]
	const  templateArray = [
        ["Date", "LME","Cluster","Product", "Quantity", "Unit Price", "Customer Name", "Customer Gender","Customer Contact", "Customer County", "Customer Sub County", "Customer Ward"]
    ]
	
  return (
    <div className="container md:px-6">
        <SaleForm saleOpen={saleOpen} setSaleOpen={setSaleOpen} />
        <div className='flex px-0 p-9 pb-4 flex-row items-center justify-between'>
			<h1>Imported Sales</h1>
			<button className='button' onClick={()=> setSaleOpen(true)}>Add Sale</button>
		</div>

        <div className="dash-card px-0 md:px-4 w-full min-h-[500px] sales">
					
					<div className="table-wrapper">
					{successImport && <Alert variant={'success'}>Data Imported Successfully</Alert>}
                    {loadingImport||loadingLMEs||  loadingUpdate || loadingDelete?<Loader fullscreen /> :errorImport|| errorLMEs || errorUpdate? <Alert variant={'error'}>{errorImport|| errorLMEs || errorUpdate || errorDelete}</Alert>:''}
                    {(!loadingImport && !errorImport) && <ToolkitProvider
							columnToggle
							
                            search
                            selectFilter
                            keyField="_id"
                            data={ importedSales.concat(sale) }
                            columns={ columns }
							exportCSV={ {
                                fileName: 'Sales.csv',
                                exportAll:false
                              } }
							  
							 
							
                            
                            
                            >
						{
							props => (
							<div>
								<div className='flex flex-wrap justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-green-500">
										Imported Sales
									</div>
									<div className='flex flex-row space-x-2'>
										<button className="button" onClick={()=>generateTemplte(templateArray, 'Sales Template')}>Template</button>
										<button className="button csv" onClick={()=>setIsOpen(true)}>Import</button>
										<button className="button csv" disabled={changes.length===0} onClick={()=>dispatch(updateSale(changes))}>Save Changes</button>
										<ExportCSVButton { ...props.csvProps } className="button csv" ><SiMicrosoftexcel /> </ExportCSVButton>
										
										<SearchBar srText={''} className='w-32' { ...props.searchProps } />
									</div>
									
								</div>
								{response && <Alert variant={'success'}>{response}</Alert>}
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-8 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() }
								cellEdit={ cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: (oldValue, newValue, row, column) => { setChanges([...changes, {value: newValue, _id: row._id, dataField: column.dataField}]); }
									}) } 
                                 
								/>
							</div>
							)
						}
						
						</ToolkitProvider>}
						{(!loadingLMEs && !errorLMEs && !loadingProducts && !errorProducts) && <ReactSpreadsheetImport allowInvalidSubmit={false} isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={fields} />}
						
					</div>
				</div>
    </div>
  )
}

export default AddSalesScreen