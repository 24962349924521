import React from "react";



function KenyaMap({counties}) {
  

  


  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg2"
      width="100%"
      
      viewBox="0 0 291.449 375.129"
      version="1.1"
      
    >
      <defs id="defs8">
        <font id="font9745" horizAdvX="1024">
          <font-face
            id="font-face9747"
            fontFamily="SVGFont 1"
            unitsPerEm="1024"
          ></font-face>
        </font>
        <font id="font9751" horizAdvX="1024">
          <font-face
            id="font-face9753"
            fontFamily="SVGFont 2"
            unitsPerEm="1024"
          ></font-face>
        </font>
      </defs>
      
      {counties.map(x=>
       <path
          key={x._id}
          
          id={x.county}
          fill={x.sales?.totalQuantity<1000?'#A7F3D0':x.sales?.totalQuantity<5000?'#86EFAC':x.sales?.totalQuantity<15000?'#22C55E':x.sales?.totalQuantity<25000?'#15803D':'#14532D'}
          d={x.d}
      >
        <title className="font-semibold capitalize">
          {x.county + ': '} &#10;

          {x.sales?.totalQuantity? x.sales?.totalQuantity.toLocaleString('en') :0 } Sales&#10;
          
          
          
          
        </title>
      </path> 
      )}
      
      
      
      
      <g id="layer2" display="inline" opacity="1">
        <text
          xmlSpace="preserve"
          style={{
            lineHeight: "125%",
            InkscapeFontSpecification: "'sans-serif, Normal'",
            WebkitTextAlign: "start",
            textAlign: "start",
          }}
          id="text9762"
          x="38.211"
          y="84.072"
          fill="#fff"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="1"
          fontFamily="sans-serif"
          fontSize="10.401"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          letterSpacing="0"
          textAnchor="start"
          wordSpacing="0"
          writingMode="lr-tb"
        >
          <tspan id="tspan9764" x="38.211" y="84.072">
            
          </tspan>
        </text>
      </g>
      
    </svg>
  );
}

export default KenyaMap;