import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { deleteLme, importLmes,  updateLme } from '../actions/lmeActions';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import LMEForm from '../forms/LMEForm'
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { SiMicrosoftexcel } from 'react-icons/si'
import idFormatter from '../functions/idFormatter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { LME_UPDATE_RESET } from '../constants/lmeConstants';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import generateTemplte from '../functions/generateTemplte';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { listMonitors } from '../actions/monitorActions';
import { BiDetail } from 'react-icons/bi';



function AddLMEsScreen() {
	const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;

	const dispatch = useDispatch()
	const [isOpen, setIsOpen] = useState(false)
	const [LMEOpen, setLMEOpen] = useState(false)
	const [changes, setChanges] = useState([])
	const navigate = useNavigate()
	const onClose = () => {setIsOpen(false)};
	const onSubmit = (data) => dispatch(importLmes(data))

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin


	const lmeCreate = useSelector(state=> state.lmeCreate)
	const {success} = lmeCreate

	const lmeUpdate = useSelector(state => state.lmeUpdate)
    const { error: errorUpdate, loading: loadingUpdate, response, success:successUpdate} = lmeUpdate

	const lmeDelete = useSelector(state => state.lmeDelete)
    const { error: errorDelete, loading: loadingDelete, success:successDelete} = lmeDelete

	const lmeImport = useSelector(state=> state.lmeImport)
	const {success: successImport, loading: loadingImport, error: errorImport, importedLmes} = lmeImport

	


	

	useEffect(() => {
		
		window.scrollTo(0,0)
		if(!userInfo){
            navigate('/')
        }
		if (successUpdate){
			setChanges([])
			setTimeout(() => {
				dispatch({type: LME_UPDATE_RESET})
				
			}, 2000);
		}
	}, [dispatch, success, successUpdate, userInfo, successDelete, success, successImport])

	const handleDelete = (id, name)=>{
        if (window.confirm(`Permanently Delete this LME: ${name}? This action is irreversible`)) {
            dispatch(deleteLme(id))
        }
    }

	const columns = [{
		dataField: '_id',
		text: '#',
		editable: false,
		
	  }, {
		dataField: 'name',
		text: 'LME Name',
		sort: true,
		sortCaret: sortCaret,
		
	  },{
		dataField: 'type_of_enterprise',
		text: 'Enterprise Type',
		editable: false,
		sort: true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options: [
				{label:'Individual', value:'Individual'},
				{label:'Group', value:'Group'},
			],
			placeholder: 'Filter by Type',
			className:'column-filter'
		  })
		
		
	  },{
		dataField:'category',
		text:'Category',
		editable:false,
		sort:true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options: [...new Set(importedLmes.map(item => item.category))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by Category',
			className:'column-filter'
		  })
	  },{
		dataField:'gender',
		text:'Gender',
		editable:true,
		sort:true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options: [...new Set(importedLmes.map(item => item.gender))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by Gender',
			className:'column-filter'
		  })
	  },{
		dataField:'cluster',
		text:'Cluster',
		sort:true,
		editable: true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(importedLmes.map(item => item.cluster))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by cluster',
			className:'column-filter'
		  })
	  },{
		dataField:'county',
		text:'County',
		sort:true,
		editable: false,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(importedLmes.map(item => item.county))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by County',
			className:'column-filter'
		  })
	  },{
		dataField: 'sub_county',
		text: 'Sub County',
		editable: false
	  },{
		dataField: 'ward',
		text: 'Ward',
		editable: false
	  },{
        dataField: 'df1',
        isDummyField: true,
        text: 'Delete',
        classes:'text-center',
        headerClasses:'text-center',
		csvExport: false,
        editable: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button onClick={()=>handleDelete(row._id, row.name)} className='delete'> <FaTrash /> </button>
              
            );
          
        }
      }];

	  const fields = [
		{
		  // Visible in table header and when matching columns.
		  label: "LME Name",
		  // This is the key used for this field when we call onSubmit.
		  key: "name",
		  // Allows for better automatic column matching. Optional.
		  alternateMatches: ["LME Name", "LMEName", 'producer name', 'name'],
		  // Used when editing and validating information.
		  fieldType: {
			type: "input"
		  },
		  validations: [
			{
			  // Can be "required" / "unique" / "regex"
			  rule: "required",
			  errorMessage: "LME Name is required",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  level: "error",
			},
		  ]
		},{
			// Visible in table header and when matching columns.
			label: "LME Gender",
			// This is the key used for this field when we call onSubmit.
			key: "gender",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["gender", "LMEGender", 'gender of LME', "sex", "seller gender"],
			// Used when editing and validating information.
			fieldType: {
				type: "select",
				options: [{label:"Male", value:"Male"}, {label:"Female", value:"Female"}, {label:"Other", value:"Other"}],
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "LME Gender is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "LME Contact",
			// This is the key used for this field when we call onSubmit.
			key: "phoneNumber",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["LME number", "LME contact", "LMEPhone", 'contact of LME', "phone number", "telephone", "phone", "tel", "contact"],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "LME Contact is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "Enterprise Type",
			// This is the key used for this field when we call onSubmit.
			key: "type_of_enterprise",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["Enterprise Type", "type", 'enterprise', 'type of enterprise'],
			// Used when editing and validating information.
			fieldType: {
			  type: "select",
			  options: [{label:"Group", value:"Group"}, {label:"Individual", value:"Individual"}],
			},
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Type of Enterprise is required",
				// There can be "info" / "warning" / "error" levels. Optional. Default "error".
				level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "LME Category",
			// This is the key used for this field when we call onSubmit.
			key: "category",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["LME category", "category", 'enterprise', 'category of lme'],
			// Used when editing and validating information.
			fieldType: {
			  type: "select",
			  options: [{label:"Producer", value:"Producer"}, {label:"Distributor", value:"Distributor"}, {label:"Stockist", value:"Stockist"}, {label:"Installer", value:"Installer"}],
			},
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "LME Category is required",
				// There can be "info" / "warning" / "error" levels. Optional. Default "error".
				level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "LME Cluster",
			// This is the key used for this field when we call onSubmit.
			key: "cluster",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["Cluster", 'cluster', "LME Cluster"],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "LME Cluster is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "LME County",
			// This is the key used for this field when we call onSubmit.
			key: "county",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["LME location", "location", 'county', "LME County"],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "County is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "LME Sub County",
			// This is the key used for this field when we call onSubmit.
			key: "subCounty",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["LME sub county","LME Sub County", "subcounty", 'sub-county', 'division', 'sub_county'],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Sub County is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "error",
			  },
			]
		  },{
			// Visible in table header and when matching columns.
			label: "LME Ward",
			// This is the key used for this field when we call onSubmit.
			key: "ward",
			// Allows for better automatic column matching. Optional.
			alternateMatches: ["LME Ward", "LME ward", "ward", 'sub-location', 'LMEward', 'sub-location'],
			// Used when editing and validating information.
			fieldType: {
			  type: "input",
			  
			},
			
			validations: [
			  {
				// Can be "required" / "unique" / "regex"
				rule: "required",
				errorMessage: "Ward is missing",
			  // There can be "info" / "warning" / "error" levels. Optional. Default "error".
			  	level: "error",
			  },
			]
		  }
	  ]
	  const  LMETemplateArray = [
        ["LME Name", "Contact Person Name",  "LME Contact","Enterprise Type", "LME Category", "LME Gender","Cluster","LME County", "LME Sub County", "LME Ward", "Group Affiliation", "Monitor"]
    ]

	  
	

	

	
  
  return (
	
	<div className="container px-6">
		<LMEForm LMEOpen={LMEOpen} setLMEOpen={setLMEOpen} />
		
		<div className='flex p-9 pb-0 flex-row items-center justify-between'>
			<h1>Add Last Mile Enterprenuers</h1>
			<button className='button' onClick={()=> setLMEOpen(true)}>Add LME</button>
		</div>
		<div className="dash-card w-full min-h-[500px]">
			{response && <Alert variant={'success'}>{response}</Alert>}
			{loadingImport||loadingImport || loadingUpdate || loadingDelete ?<Loader fullscreen /> :errorImport||errorImport || errorUpdate || errorDelete? <Alert variant={'error'}>{errorImport||errorImport || errorUpdate || errorDelete}</Alert>:''}
			
					
					<div className="table-wrapper">
						
						{(!errorImport && !loadingImport) && <ToolkitProvider
                            search
                            selectFilter
                            keyField="_id"
                            data={ importedLmes }
                            columns={ columns }
							exportCSV={ {
                                fileName: 'ImportedLmes.csv',
                              } }
                            
                            >
						{
							props => (
							<div>
								<div className='flex-wrap flex justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-green-500">
										Imported Lmes
									</div>
									<div className='flex flex-row space-x-2'>
										<button className="button" onClick={()=>generateTemplte(LMETemplateArray, 'LME Template')}>Template</button>
										<button className="button csv" onClick={()=>setIsOpen(true)}>Import</button>
										<button className='button' disabled={changes.length===0} onClick={()=>dispatch(updateLme(changes))}>Save Changes</button>
										<ExportCSVButton { ...props.csvProps } className="py-2 px-3 button csv bg-green-500 rounded-lg" > <SiMicrosoftexcel /> </ExportCSVButton>
										<SearchBar srText={''} className='w-32' { ...props.searchProps } />
									</div>
									
								</div>
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-0 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() } 
								
								cellEdit={ cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: (oldValue, newValue, row, column) => { setChanges([...changes, {value: newValue, _id: row._id, dataField: column.dataField}]); }
									}) } 
								/>
							</div>
							)
						}
						</ToolkitProvider>}
						<ReactSpreadsheetImport allowInvalidSubmit={false} isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} fields={fields} />
					</div>
		</div>
			
	</div>
  )
}

export default AddLMEsScreen