import { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import CustomerScreen from "./screens/CustomerScreen";
import DashBoardScreen from "./screens/DashBoardScreen";
import LMEsScreen from "./screens/LMEsScreen";
import LoginScreen from "./screens/LoginScreen";
import MonitorScreen from "./screens/MonitorScreen";
import ProductScreen from "./screens/ProductScreen";
import SaleScreen from "./screens/SalesScreen";
import LMEDetailsScreen from "./screens/LMEDetailsScreen";
import AddSalesScreen from "./screens/AddSalesScreen";
import AddLMEsScreen from "./screens/AddLMEsScreen";



function App() {
  const [collapsed, setCollapsed] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(window.location.pathname)
  return (
    <Router>
      
      
      
    <div className="flex-col md:flex-row w-full min-h-screen bg-gray-50">
    {currentLocation!=='/' && <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />}
      <main className={`ml-0 md:ml-60 transition-all duration-700 ease-in-out ${collapsed}`}>
        <Header currentLocation={currentLocation} setCurrentLocation={setCurrentLocation} />
        <Routes>
          <Route path="/"  element={<LoginScreen setCollapsed={setCollapsed} />} />
          <Route path="/home" element={<DashBoardScreen />} />
          <Route path="/lmes" element={<LMEsScreen />} />
          <Route path="/products" element={<ProductScreen />} />
          <Route path="/monitors" element={<MonitorScreen />} />
          <Route path="/customers" element={<CustomerScreen />} />
          <Route path="/sales" element={<SaleScreen />} />
          <Route path="/lme"  element={<LMEDetailsScreen  />} />
          <Route path="/sale/add" element={<AddSalesScreen />} />
          <Route path="/lme/add" element={<AddLMEsScreen />} />
        </Routes>
      </main>
      
    </div>
    </Router>
  );
}

export default App;
