import {
    MONITOR_LIST_REQUEST,
    MONITOR_LIST_SUCCESS,
    MONITOR_LIST_FAIL,

    MONITOR_DETAILS_REQUEST,
    MONITOR_DETAILS_SUCCESS,
    MONITOR_DETAILS_FAIL,

    MONITOR_DELETE_REQUEST,
    MONITOR_DELETE_SUCCESS,
    MONITOR_DELETE_FAIL,

    MONITOR_CREATE_REQUEST,
    MONITOR_CREATE_SUCCESS,
    MONITOR_CREATE_FAIL,
    MONITOR_CREATE_RESET,

    MONITOR_UPDATE_REQUEST,
    MONITOR_UPDATE_SUCCESS,
    MONITOR_UPDATE_FAIL,
    MONITOR_UPDATE_RESET,

} from '../constants/monitorConstants'


export const monitorListReducer = (state = { monitors: [], selectOptions: [] }, action) => {
    switch (action.type) {
        case MONITOR_LIST_REQUEST:
            return { loading: true, monitors: [] }

        case MONITOR_LIST_SUCCESS:
            
            return {
                loading: false,
                monitors: action.payload
            }

        case MONITOR_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}



export const monitorDetailsReducer = (state = { monitor: { reviews: [] } }, action) => {
    switch (action.type) {
        case MONITOR_DETAILS_REQUEST:
            return { loading: true, ...state }

        case MONITOR_DETAILS_SUCCESS:
            return { loading: false, monitor: action.payload }

        case MONITOR_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const monitorCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case MONITOR_CREATE_REQUEST:
            return { loading: true }

        case MONITOR_CREATE_SUCCESS:
            return { loading: false, success: true, monitor: action.payload }

        case MONITOR_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case MONITOR_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const monitorDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case MONITOR_DELETE_REQUEST:
            return { loading: true }

        case MONITOR_DELETE_SUCCESS:
            return { loading: false, success: true }

        case MONITOR_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const monitorUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case MONITOR_UPDATE_REQUEST:
            return { loading: true }

        case MONITOR_UPDATE_SUCCESS:
            return { loading: false, success: true, response: action.payload }

        case MONITOR_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case MONITOR_UPDATE_RESET:
            return { state: {} }

        default:
            return state
    }
}


