import React, { useEffect, useState } from 'react'
// import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { GiCampCookingPot} from 'react-icons/gi';
import { TfiShoppingCartFull, TfiHome } from 'react-icons/tfi';
import { AiOutlineShop, AiOutlineUser } from 'react-icons/ai'
import { FaCoins } from 'react-icons/fa';
import CountUp from 'react-countup';
import ReactApexChart from 'react-apexcharts'
import DashChart from '../components/DashChart';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { listLmeDetails } from '../actions/lmeActions';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import Alert from '../components/Alert';



function LMEDetailsScreen() {
	
	const [cash, setCash] = useState('quantity')

	

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const dispatch = useDispatch()
	const navigate = useNavigate()
	const geoURL = 'https://raw.githubusercontent.com/DeveloperFelix/Kenya-County-GeoJSON-Downloader/master/json/ke.geojson'
	const [searchParams] = useSearchParams();
	const id = searchParams.get('id');

	const lmeDetails = useSelector(state => state.lmeDetails)
	const {loading, error, lme, products, sales, customers,sales_per_product, product_sales_trend, sales_trend } = lmeDetails

	



	useEffect(() => {
        window.scrollTo(0,0)
        if(!userInfo){
            navigate('/')
        }else{
            dispatch(listLmeDetails(id))
            
        }
        
    }, [userInfo, dispatch, id])

	

	

	
	
	
	
	const trendSeries = products.map((p)=>{
		var filteredArray = product_sales_trend.filter(item => item.product==p)
		
          sales_trend.forEach(element => {
			
            if (!(filteredArray.find(item => item.month==element.month && item.year==element.year))){
				
              filteredArray.push({
                  month:element.month,
                  totalCash:0,
                  totalQuantity:0,
                  product: p,
				  year:element.year,
              })
			  
            }
          });
		  const productArray = filteredArray.sort((a,b) => new Date("01 "+a.month + " " +a.year).getTime() - new Date("01 "+b.month + " " +b.year).getTime())
		  
		  return {name: p, data: productArray.map((item)=> { return {x:item.month+' '+ item.year, y:item.totalQuantity}} )}
	})

	const trendSeries1 = products.map((p)=>{
		var filteredArray = product_sales_trend.filter(item => item.product===p)
          sales_trend.forEach(element => {
            if (!(filteredArray.find(item => item.month===element.month))){
              filteredArray.push({
                  month:element.month,
                  totalCash:0,
                  totalQuantity:0,
                  product: p,
              })
            }
          });
		  const productArray = filteredArray.sort((a,b) => new Date(a.month).getTime() - new Date(b.month).getTime())
		  return {name: p, data: productArray.map((item)=> { return {x:new Date(item.month).toLocaleString('default', { month: 'long' })+' '+new Date(item.month).getFullYear(), y:item.totalCash}} )}
	})

	

	  const options = {
		
		chart: {
		  height: 500,
		  type: 'area',
		  
		  zoom: {
			enabled: false
		  }
		},
		dataLabels: {
		  enabled: false
		},
		stroke: {
		  curve: 'smooth'
		},
		
		tooltip: {
			x: {
			  format: 'dd/MM/yyyy'
			},
		  },
		}

		const pieSeries =  sales_per_product.map(item=>item.totalQuantity)
		const pieSeries1 =  sales_per_product.map(item=>item.totalCash)
		
		const pieOptions = {
			chart: {
			
			type: 'pie',
			},
			
			labels: sales_per_product.map(item=>item.product),
			responsive: [{
				breakpoint: 480,
					options: {
						chart: {
						
						},
						legend: {
						position: 'bottom'
						}
					}
				}],
			fill: {
				type: 'gradient',
			},
			dataLabels: {
				enabled: false
			}

		}

		

		
		
		
		  useEffect(() => {
			window.scrollTo(0,0)
		  }, [])
		
		  
	return (
		<div className="container">
			
			<h1 className='p-9 pb-0 font-bold text-green-500'>{lme.name}</h1>
			{(loading ) ? <Loader fullscreen />: 
			(error) ? <Alert variant={'error'}>{error}</Alert>:
			<div className="row">
				
				

				

				<div className="col-4 ">
					<div className="dash-card">
						<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Total Sales</div>
								<div className='dash-card-value'>
									<CountUp
										start={0}
										end={sales.reduce((acc, item) => acc + Number(item.quantity), 0)}
										duration={1}
										separator=","
										
									/>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<TfiShoppingCartFull className='dash-card-icon' />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-4 ">
					<div className="dash-card">
						<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Sales Amount</div>
								<div className='dash-card-value'>
									<CountUp
										start={0}
										end={sales.reduce((acc, item) => acc + Number(item.totalPrice), 0)}
										duration={1}
										separator=","
										prefix='Ksh. '
										
									/>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<FaCoins className='dash-card-icon' />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-4 ">
					<div className="dash-card">
						<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Total Customers</div>
								<div className='dash-card-value'>
									<CountUp
										start={0}
										end={customers.length}
										duration={1}
										separator=","
									/>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<AiOutlineUser className='dash-card-icon' />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-4 ">
					<div className="dash-card">
						<div className="dash-card-content">
							<div className="dash-card-text">
								<div className='dash-card-label'>Products</div>
								<div className='dash-card-value'>
									<CountUp
										start={0}
										end={products.length}
										duration={1}
										separator=","
										
									/>
								</div>
							</div>

							<div className="dash-card-figure">
								<div className="dash-card-icon-wrapper">
									<GiCampCookingPot className='dash-card-icon' />
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>}
			<div className='md:mt-6 flex justify-end pr-8'>
				<select value={cash} onChange={(e)=>setCash(e.target.value)}>
					<option value={'quantity'}>Quantity</option>
					<option value={'cash'}>Cash</option>
				</select>
			</div>

			<div className="row">
				
				<div className='col-2'>
					<div className="dash-card w-full md:min-h-[500px]">
						<div className="card-title px-4 font-bold text-green-500">
							Sales Trend
						</div>
						<div className="w-auto overflow-hidden">
							{(!error && !loading) && <ReactApexChart options={options} series={cash==='cash' ? trendSeries1:trendSeries} type="area" height={400} />}
						</div>
						
					</div>
				</div>

				<div className='col-2'>
					<div className="dash-card w-full md:min-h-[500px]">
						<div className="card-title px-4 font-bold text-green-500">
							Sales Per Product
						</div>
						<div className="w-full overflow-hidden">
							{(!error && !loading) &&<DashChart options={pieOptions} data={cash==='cash'? pieSeries1: pieSeries} type={"pie"} height={400}/>}
						</div>
						
					</div>
				</div>

				

				



			</div>

	

			<div className="row md:mt-6">

			<div className="dash-card w-full md:min-h-[500px] mb-8">
							<div className="card-title px-4 font-bold text-green-500 ">
								Customers for {lme.name}
							</div>
							<div className="table-wrapper">
								{/* <KenyaMap /> */}
								<table className='items-center w-full mb-0 align-top border-gray-200 text-slate-500'>
									<thead>
										<tr>
											<th>#</th>
											<th>Customer</th>
											<th>Phone Number</th>
											<th>County</th>
											<th>Quantity</th>
											<th>Total Price</th>
										</tr>
									</thead>
									<tbody>
									<tr></tr>
										{loading? '' : error? '':customers.sort((a, b)=> {return b.totalQuantity - a.totalQuantity}).map((l, index)=>
											
											<tr key={index}>
												<td>{index}</td>
												<td>{l.customer_name}</td>
												<td>{l.phoneNumber}</td>
												<td>{l.county}</td>
												<td>{l.totalQuantity}</td>
												<td>{l.totalCash}</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							
						</div>
				
					
						<div className="dash-card w-full md:min-h-[500px]">
							<div className="card-title px-4 font-bold text-green-500">
								Sales by {lme.name}
							</div>
							<div className="table-wrapper">
								{/* <KenyaMap /> */}
								<table className='items-center w-full mb-0 align-top border-gray-200 text-slate-500'>
									<thead>
									<tr><th>Date</th>
										<th>Product</th>
										<th>Customer</th>
										<th>Quantity</th>
										<th>Unit Price</th>
										<th>Total Price</th></tr>
										
									</thead>
									<tbody>
									<tr></tr>
										{loading? '' : error? '':sales.map(l=>
											<tr key={l._id}>
												<td>{l.date}</td>
												<td>{l.product}</td>
												<td>{l.customer_name}</td>
												<td>{l.quantity}</td>
												<td>{l.unitPrice}</td>
												<td>{l.totalPrice}</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							
						</div>

					

					
			</div>
		</div>
		
	)
}

export default LMEDetailsScreen