import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { getCountyDropdown } from '../actions/dropdownActions'
import OutsideAlerter from "../functions/listen-for-outside-clicks";
import PhoneInput from 'react-phone-input-2'
import { createCustomer } from '../actions/customerActions';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
function CustomerForm({customerOpen, setCustomerOpen}) {
    const dispatch = useDispatch()
	const countyDropdown = useSelector(state=> state.countyDropdown)
	const {wards, counties, loading:loadingCounties, error:errorCounties} = countyDropdown

    const customerCreate = useSelector(state=> state.customerCreate)
	const {success, loading:loadingCreate, error:errorCreate} = customerCreate

	const [county, setCounty] = useState('')
    const [subCounty, setSubCounty] = useState('')
    const [ward, setWard] = useState('')
	const [customerName, setCustomerName] = useState('')
	const [customerCategory, setCustomerCategory] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	
    

    const handleCountyChange = (newValue, actionMeta) => {
        

        if (newValue){
            setCounty(newValue.value)
        }else{
            setCounty('')
        }
        
    };
	const handleSubCountyChange = (newValue, actionMeta) => {
        

        if (newValue){
            setSubCounty(newValue.value)
        }else{
            setSubCounty('')
        }
        
    };

    const handleWardChange = (newValue, actionMeta) => {
        

        if (newValue){
            setWard(newValue.value)
        }else{
            setWard('')
        }
        
    };

	

	const customerSubmitHandler = (e)=>{
		e.preventDefault()
        dispatch(createCustomer({customerName, customerCategory, phoneNumber, county, subCounty, ward}))
		
	} 
	
	useEffect(() => {
	  dispatch(getCountyDropdown())

      if (success) {
        
        setCustomerCategory('')
        setCustomerName('')
        setCounty('')
        setSubCounty('')
        setPhoneNumber('')
        setCustomerOpen(false)
        setWard('')
      }
      
	}, [dispatch, success, setCustomerOpen])
	
	const selectedSubCounties = wards.filter(n=>n.county===county)
	const selectedWards = wards.filter(n=>n.sub_county===subCounty)
  return (
    <div className={`modal-container ${customerOpen ? 'flex' : 'hidden'}`}>
            {customerOpen && <OutsideAlerter setOpen={setCustomerOpen}>
			    <div className="modal-content small">
				<h2 className="modal-header">
					Add customer
				</h2>
				<div className="modal-body">
					<form className='flex flex-wrap' onSubmit={customerSubmitHandler}>
                        						
                            <div className="form-group">
                                <label htmlFor="customerName" className="form-label">Name</label>
                                <input value={customerName} onChange={(e)=> setCustomerName(e.target.value)} required type="text" placeholder='Please Enter customer Name' name="customerName" id="customerName" className="form-input" />
                            </div>


                            {/* <div className="form-group">
                                <label htmlFor="customerCategory" className="form-label">Category</label>
                                <select value={customerCategory} onChange={(e)=> setCustomerCategory(e.target.value)} required  name="customerCategory" id="customerCategory" className="form-input" >
                                    <option value=''>--Select customer Category--</option>
                                    <option value='Producer'>Producer</option>
                                    <option value='Distributor'>Distributor</option>
                                    <option value='Stockist'>Stockist</option>
                                    <option value='Installer'>Installer</option>
                                </select>
                            </div> */}

                            <div className="form-group">
                                <label htmlFor="phoneNumber" className="form-label">Phone Number </label>
                                <PhoneInput
                                    onlyCountries={['ke','ug','sn']}
                                    country='ke'
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    inputClass='form-input'
                                    placeholder='+254 712 345 678'
                                    copyNumbersOnly={false}
                                    countryCodeEditable={false}
                                />
                            </div>
                       
                            <div className="form-group">
                                <label htmlFor="county" className="form-label">County</label>
                                <Select isClearable isSearchable options={counties} onChange={handleCountyChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="subCounty" className="form-label">Sub County</label>
                                <Select isDisabled={county === ''} isClearable isSearchable options={[...new Set (selectedSubCounties.map(c => c.sub_county))].map((x)=> { return {value:x, label:x}} )} onChange={handleSubCountyChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="ward" className="form-label">Ward</label>
                                <Select isDisabled={subCounty === ''} isClearable isSearchable options={selectedWards.map((x)=> { return {value:x._id, label:x.ward}} )} onChange={handleWardChange} />
                            </div>

                            
                            
                            <div className="w-full px-6 hover:scale-101">
                                <button type="submit" className='form-submit'>Save</button>
                            </div>

                        {(errorCreate|| errorCounties) && <Alert variant='error' >{errorCreate|| errorCounties}</Alert>}
						
					</form>
				</div>
			    </div>
            </OutsideAlerter>}
            {(loadingCreate || loadingCounties) && <Loader fullscreen />}
		</div>
  )
}

export default CustomerForm