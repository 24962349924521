import * as XLSX from 'xlsx/xlsx.mjs';

function generateTemplte(headers, fileName) {
    

    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.book_new();
    
    // Process Data (add a new row)
    var worksheet = XLSX.utils.aoa_to_sheet(headers);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    
    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

export default generateTemplte