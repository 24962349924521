import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MonitorForm from '../forms/MonitorForm'
import { deleteMonitor, listMonitors, updateMonitor } from '../actions/monitorActions';
import Alert from '../components/Alert';
import Loader from '../components/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import sortCaret from '../functions/sortCaret';
import idFormatter from '../functions/idFormatter';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { SiMicrosoftexcel } from 'react-icons/si'
import cellEditFactory from 'react-bootstrap-table2-editor';
import { MONITOR_UPDATE_RESET } from '../constants/monitorConstants';
import { useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa'

function MonitorScreen() {
    const [monitorOpen, setMonitorOpen] = useState(false)
	const[changes, setChanges]= useState([])
    const dispatch = useDispatch()

	const navigate = useNavigate()

	const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
	

	const monitorList = useSelector(state=> state.monitorList)
	const {loading: loadingMonitors, error: errorMonitors, monitors} = monitorList

	const monitorCreate = useSelector(state=> state.monitorCreate)
	const {success} = monitorCreate

	const monitorUpdate = useSelector(state => state.monitorUpdate)
    const { error: errorUpdate, loading: loadingUpdate, response, success:successUpdate} = monitorUpdate

	const monitorDelete = useSelector(state => state.monitorDelete)
    const { error: errorDelete, loading: loadingDelete, success:successDelete} = monitorDelete


    

	useEffect(() => {
		window.scrollTo(0,0)
		if(!userInfo){
            navigate('/')
        }else{
			dispatch(listMonitors())
		}
		if (successUpdate){
			setChanges([])
			setTimeout(() => {
				dispatch({type: MONITOR_UPDATE_RESET})
				
			}, 2000);
		}
	}, [dispatch, success, successUpdate, userInfo, successDelete])

    const { SearchBar } = Search;
	const { ExportCSVButton } = CSVExport;

	const handleDelete = (id, name)=>{
        if (window.confirm(`Permanently Delete this Monitor: ${name}? This action is irreversible`)) {
            dispatch(deleteMonitor(id))
        }
    }
    

    const columns = [{
		dataField: '_id',
		text: '#',
        formatter: idFormatter,
		editable: false,
        
	  }, {
		dataField: 'name',
		text: 'Monitor Name',
		sort: true,
		sortCaret: sortCaret,
		editable: true
		
	  },{
		dataField:'county',
		text:'County',
		editable: false,
		sort:true,
		sortCaret: sortCaret,
		filter: selectFilter({
			options:  [...new Set(monitors.map(item => item.county))].map((item)=> { return {value:item, label:item}} ),
			placeholder: 'Filter by County',
			className:'column-filter'
		  })
	  },{
		dataField:'phone_number',
		text:'Contact',
		editable: true,
		sort:true,
		sortCaret: sortCaret,
        
	  },{
		dataField:'gender',
		text:'Gender',
		editable: true,
		sort:true,
		sortCaret: sortCaret,
        
	  },{
        dataField: 'df1',
        isDummyField: true,
        text: 'Delete',
        classes:'text-center',
        headerClasses:'text-center',
        editable: false,
        formatter: (cellContent, row) => {
          
            return (
              
                <button onClick={()=>handleDelete(row._id, row.name)} className='delete'> <FaTrash /> </button>
              
            );
          
        }
      }];
  return (
    <div className="container px-6">
        <MonitorForm monitorOpen={monitorOpen} setMonitorOpen={setMonitorOpen} />
        <div className='flex px-0 p-9 pb-4 flex-row items-center justify-between'>
			<h1>Monitors</h1>
			<button className='button' onClick={()=> setMonitorOpen(true)}>Add Monitor</button>
		</div>

        <div className="dash-card w-full min-h-[500px]">
					
					<div className="table-wrapper">
                    {loadingMonitors || loadingUpdate || loadingDelete?<Loader fullscreen /> :errorMonitors || errorUpdate|| errorDelete? <Alert variant={'error'}>{errorMonitors || errorUpdate|| errorDelete}</Alert>:''}
                    {(!errorMonitors && !loadingMonitors) && <ToolkitProvider
                            search
                            selectFilter
                            keyField="_id"
                            data={ monitors }
                            columns={ columns }
							exportCSV={ {
                                fileName: 'Producers.csv',
                                exportAll:false
                              } }
                            
                            
                            >
						{
							props => (
							<div>
								<div className='flex flex-wrap justify-between items-center mb-4'>
									<div className="card-title px-4 font-bold text-green-500">
										Monitors
									</div>
									<div className='flex flex-row space-x-2'>
										<button className='button' disabled={changes.length===0} onClick={()=>dispatch(updateMonitor(changes))}>Save Changes</button>
										<ExportCSVButton { ...props.csvProps } className="button csv" > <SiMicrosoftexcel /> </ExportCSVButton>
										<SearchBar srText={''} className='w-32' { ...props.searchProps } />
									</div>
									
								</div>

								{response && <Alert variant={'success'}>{response}</Alert>}
								
								<BootstrapTable
								{ ...props.baseProps }
								classes='items-center w-full mb-0 align-top border-gray-200 text-slate-500'
								filter={ filterFactory() }
								cellEdit={ cellEditFactory({
									mode: 'click',
									blurToSave: true,
									afterSaveCell: (oldValue, newValue, row, column) => { setChanges([...changes, {value: newValue, _id: row._id, dataField: column.dataField}]); }
									}) } 
                                 
								/>
							</div>
							)
						}
						</ToolkitProvider>}
					</div>
				</div>
    </div>
  )
}

export default MonitorScreen