import axios from 'axios'
import {
    MONITOR_LIST_REQUEST,
    MONITOR_LIST_SUCCESS,
    MONITOR_LIST_FAIL,

    MONITOR_DETAILS_REQUEST,
    MONITOR_DETAILS_SUCCESS,
    MONITOR_DETAILS_FAIL,

    MONITOR_DELETE_REQUEST,
    MONITOR_DELETE_SUCCESS,
    MONITOR_DELETE_FAIL,

    MONITOR_CREATE_REQUEST,
    MONITOR_CREATE_SUCCESS,
    MONITOR_CREATE_FAIL,

    MONITOR_UPDATE_REQUEST,
    MONITOR_UPDATE_SUCCESS,
    MONITOR_UPDATE_FAIL,

} from '../constants/monitorConstants'


export const listMonitors = () => async (dispatch, getState) => {
    try {
        dispatch({ type: MONITOR_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/monitors/`, config)

        dispatch({
            type: MONITOR_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MONITOR_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}




export const listMonitorDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: MONITOR_DETAILS_REQUEST })

        const { data } = await axios.get(`/api/monitors/${id}`)

        dispatch({
            type: MONITOR_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MONITOR_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteMonitor = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MONITOR_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/monitors/delete/${id}/`,
            {},
            config
        )

        dispatch({
            type: MONITOR_DELETE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: MONITOR_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}




export const createMonitor = (monitor) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MONITOR_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/monitors/create/`,
            monitor,
            config
        )
        dispatch({
            type: MONITOR_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: MONITOR_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const updateMonitor = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MONITOR_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const  { data }  = await axios.post(
            `/api/monitors/update/`,
            changes,
            config
        )
        dispatch({
            type: MONITOR_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: MONITOR_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: MONITOR_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
